import React from 'react'
import Generic from '../Generic'
import External from '../Layouts/External'
import ModalAlert from '../Tools/ModalAlert'
import { Card, Button, CardHeader, CardFooter, CardBody,
  CardTitle, FormGroup, Label, Input } from 'reactstrap';

import Cookies from 'universal-cookie'

export default class Newversion extends Generic {

  constructor(props){
    super(props)
    this.state = {
      year: new Date().getFullYear()
    }
  }

  componentDidMount(){
  }

  goLogin(){
    const cookies = new Cookies();
    cookies.set('newversion','visualized')
    this.props.history.push("/login");
  }

  render(){
    return(
      <External loading={this.state.loading}>
        <Card className="newversion">
          <CardBody>
              <div className='text-center'>
                <h2>
                    Bem vindo(a) à nova versão do Taxview!
                </h2>
                <br/>
                <h6>
                    <p>
                        Remodelamos completamente o Taxview, passamos a utilizar tecnologias modernas
                        que assim permitirão uma atualização mais constante do sistema.
                    </p>
                    <p>
                        As atuais funcionalidades foram mantidas, porém houveram mudanças mínimas de layout,
                        justamente devido à modernização da plataforma.
                    </p>
                    <p>
                        Trabalharemos para implementação de novas funcionalidades e melhoria na experiência de
                        uso da mesma.
                    </p>
                    <p>
                        Desde já contamos com a sua compreensão e colaboração, qualquer problema nos informe
                        pelo e-mail <strong>suporte@inteligenciafiscaldigital.com.br</strong>.
                    </p>
                </h6>
                <br/><br/>
                <Button color="danger" block onClick={() => this.goLogin()}><i className="fas fa-sign-in-alt"></i> Ir para o Login</Button>{' '}
            </div>
          </CardBody>
        </Card>
      </External>
    )
  }
}