import React from 'react'
import External from '../Layouts/External'
import Generic from '../Generic'
import queryString from 'query-string';
import ModalAlert from '../Tools/ModalAlert'
import { Card, Button, CardHeader, CardFooter, CardBody,
  CardTitle, FormGroup, Label, Input, Alert } from 'reactstrap';

import TokenService from '../../Services/TokenService'
import UserService from '../../Services/UserService'

export default class RedefinePassword extends Generic {
    constructor(props){
        super(props)
        this.state = {
            redirect: false,
            url: '/login',
            id: 0,
            senha: '',
            senha_confirmation: '',
            modal: {
                showModal: false,
                title: '',
                message: ''
            },
            loading: false,
            year: new Date().getFullYear()
          }
    }

    componentWillMount(){
        const values = queryString.parse(this.props.location.search);
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res => {
            let token = res.data.access_token
            new UserService(token).enableRedefinePassword(values.token)
            .then(res => {
                this.setState({
                    id:res.data.return_value,
                    loading:false
                })
            })
            .catch(error => {
                this.setState({
                    id:0,
                    loading:false,
                    redirect: true
                })
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({
                id:0,
                loading:false,
                redirect: true
            })
            this.showError(error)
        })
    }

    redefine(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            var body = {
                id: this.state.id,
                senha: this.state.senha,
                senha_confirmation: this.state.senha_confirmation
            }
            new UserService(token).changepassword(body)
            .then(res=>{
                //localStorage.setItem(btoa('user'),btoa(JSON.stringify(res.data.return_value)));
                this.setState({
                    modal: {
                        showModal: true,
                        title: 'Alteração de Senha',
                        message: 'Senha alterada com sucesso!'
                    },
                    redirect: true
                });
            })
            .catch(error=>{
                this.setState({loading:false,redirect:false,senha:'',senha_confirmation:''})
                this.showError(error)
            })
        })
        .catch(error => {
            this.setState({loading:false,redirect:true})
            this.showError(error)
        })
    }

    form(){
        if (this.state.id>0){
            return (
                <div>
                    <Card className="login">
                        <CardHeader className="text-center login" tag="h2">Redefinir sua senha</CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label for="senha"><strong>Senha</strong></Label>
                                <Input type="password" name="senha" id="senha" placeholder="Digite a senha" value={this.state.senha} onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="senha_confirmation"><strong>Confirme a Senha</strong></Label>
                                <Input type="password" name="senha_confirmation" id="senha_confirmation" placeholder="Confirme a senha" value={this.state.senha_confirmation} onChange={this.handleChange}/>
                            </FormGroup>
                            <Button color="primary" block onClick={() => this.redefine()}><i className="fas fa-exchange-alt"></i> Alterar</Button>{' '}
                        </CardBody>
                        <CardFooter className="text-right login">
                            &copy; 2012-{ this.state.year } Taxview - Inteligência Fiscal Digital
                        </CardFooter>
                    </Card>
                </div>
            )
        } else {
            return(
                <div></div>
            )
        }
    }

    error(){
        return (
            <div>
                <Alert color="danger">
                    O token fornecido é inválido!
                </Alert>
            </div>
        )
    }

    render(){
        return(
            <External loading={this.state.loading}>
                { this.form() }
                <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
            </External>
        )
    }
}