import React from 'react'
import Internal from '../Layouts/Internal'
import Generic from '../Generic'
import {
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Alert,
  Table,
  UncontrolledTooltip
 } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import TokenService from '../../Services/TokenService'
import EmpresaService from '../../Services/EmpresaService'
import ModalAlert from '../Tools/ModalAlert'
import FormEmpresa from './FormEmpresa'

export default class Empresa extends Generic {

  constructor(props){
    super(props)
    this.state = {
      empresas: null,
      current_page: 1,
      last_page: 1,
      searchValue: '',
      loading: false,
      modal: {
          showModal: false,
          title: '',
          message: ''
      },
      newEmpresa: false,
      idEmpresa: 0,
      showActivateEmpresa: true,
      empresaAtiva: 0
    }
  }

  componentWillMount(){
    this.setState({ loading: true})
    new TokenService().getToken()
    .then(res => {
      let token = res.data.access_token
      new EmpresaService(token).all(this.globalUser.idUsuario)
      .then(res => {
        this.setState({ loading: false})
        this.setState({
          empresas: res.data.return_value.data,
          current_page: res.data.return_value.current_page,
          last_page: res.data.return_value.last_page
        })
      })
      .catch(error => {
        this.setState({ loading: false})
        this.showError(error)
      })
    })
    .catch(error => {
      this.setState({ loading: false})
      this.showError(error)
    })
  }

  firstPage(){
    this.setState({
      current_page: 1
    })
    this.search()
  }

  previousPage(){
    if (this.state.current_page>1){
      this.setState({
        current_page: this.state.current_page-1
      })
      this.search()
    }
  }

  nextPage(){
    if (this.state.current_page<this.state.last_page){
      this.setState({
        current_page: this.state.current_page+1
      })
      this.search()
    }
  }

  lastPage(){
    this.setState({
      current_page: this.state.last_page
    })
    this.search()
  }

  drawPagination(){
    return(
      <Pagination aria-label="Page navigation example">
        { this.drawFirstPage() }
        { this.drawPreviousPage() }
        <PaginationItem>
          <PaginationLink>
            {this.state.current_page}/{ this.state.last_page}
          </PaginationLink>
        </PaginationItem>
        { this.drawNextPage() }
        { this.drawLastPage() }
      </Pagination>
    )
  }

  drawLastPage(){
    var uicomponent = null
    if (this.state.current_page===this.state.last_page){
      uicomponent = <PaginationItem disabled>
        <PaginationLink onClick={()=>this.lastPage()}>
          Última
        </PaginationLink>
      </PaginationItem>
    } else {
      uicomponent = <PaginationItem>
        <PaginationLink onClick={()=>this.lastPage()}>
          Última
        </PaginationLink>
      </PaginationItem>
    }

    return uicomponent
  }

  drawNextPage(){
    var uicomponent = null
    if (this.state.current_page===this.state.last_page){
      uicomponent = <PaginationItem disabled>
        <PaginationLink onClick={()=>this.nextPage()}>
          Próxima
        </PaginationLink>
      </PaginationItem>
    } else {
      uicomponent = <PaginationItem>
        <PaginationLink onClick={()=>this.nextPage()}>
          Próxima
        </PaginationLink>
      </PaginationItem>
    }

    return uicomponent
  }

  drawFirstPage(){
    var uicomponent = null
    if (this.state.current_page===1){
      uicomponent = <PaginationItem disabled>
        <PaginationLink onClick={()=>this.firstPage()}>
          Primeira
        </PaginationLink>
      </PaginationItem>
    } else {
      uicomponent = <PaginationItem>
        <PaginationLink onClick={()=>this.firstPage()}>
          Primeira
        </PaginationLink>
      </PaginationItem>
    }

    return uicomponent
  }

  drawPreviousPage(){
    var uicomponent = null
    if (this.state.current_page===1){
      uicomponent = <PaginationItem disabled>
        <PaginationLink  onClick={()=>this.previousPage()}>
          Anterior
        </PaginationLink>
      </PaginationItem>
    } else {
      uicomponent = <PaginationItem>
        <PaginationLink  onClick={()=>this.previousPage()}>
          Anterior
        </PaginationLink>
      </PaginationItem>
    }

    return uicomponent
  }

  drawTable(){
    if (this.state.empresas===null){
      return(
        <div></div>
      )
    }
    if (this.state.empresas.length === 0){
      return(
        <Alert color="primary">
          <i className="fas fa-exclamation-triangle"></i> Nenhuma empresa cadastrada!
        </Alert>
      )
    } else {
      return(
        <div>
          <div className="row">
            <div className="col-12">
              <br/>
              {this.drawPagination()}
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th style={{width: '25%'}}>CNPJ</th>
                <th>Razão Social</th>
                <th style={{width: '15%'}}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.empresas.map(empresa => {
                  if (empresa.removido === 0 ){
                    return (
                      <tr key={empresa.idEmpresa}>
                        <td>{ empresa.idEmpresa }</td>
                        <td>{ this.formatCnpj(empresa.CNPJ)}</td>
                        <td>{empresa.razaoSocial}</td>
                        <td>
                          <Button color="link" size="sm" id={'activate'+empresa.idEmpresa} onClick={()=>this.activateEmpresa(empresa.idEmpresa)}><i className="fas fa-share-square"></i></Button>
                          <Button style={{ display: this.globalUser.qtdeEmpresasPermitidas > 0 ? 'none':'inline' }} color="link" size="sm" id={'delete'+empresa.idEmpresa} onClick={()=>{ if (window.confirm('Tem certeza que deseja excluir esta empresa?')) this.removeEmpresa(empresa.idEmpresa) }}><i className="fas fa-trash-alt"></i></Button>
                          <Button color="link" size="sm" id={'edit'+empresa.idEmpresa} onClick={()=>this.editEmpresa(empresa.idEmpresa)}><i className="fas fa-edit"></i></Button>
                          <UncontrolledTooltip placement="left" target={'activate'+empresa.idEmpresa}>
                            Ativar Empresa
                          </UncontrolledTooltip>
                          <UncontrolledTooltip placement="top" target={'delete'+empresa.idEmpresa}>
                            Apagar Empresa
                          </UncontrolledTooltip>
                          <UncontrolledTooltip placement="bottom" target={'edit'+empresa.idEmpresa}>
                            Editar Empresa
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </Table>
          <div className="row">
            <div className="col-12">
              {this.drawPagination()}
            </div>
          </div>
      </div>
      )
    }
  }

  search(){
    this.setState({ loading: true})
    new TokenService().getToken()
    .then(res => {
      let token = res.data.access_token
      new EmpresaService(token).navigate(this.globalUser.idUsuario,this.state.current_page,this.state.searchValue)
      .then(res => {
        this.setState({ loading: false})
        console.log(res.data)
        this.setState({
          empresas: res.data.return_value.data,
          current_page: res.data.return_value.current_page,
          last_page: res.data.return_value.last_page
        })
      })
      .catch(error => {
        this.setState({ loading: false})
        this.showError(error)
      })
    })
    .catch(error => {
      this.setState({ loading: false})
      this.showError(error)
    })
  }

  cancel(){
    this.setState({
      searchValue: ''
    })
    this.search()
  }

  createEmpresa(){
    this.setState({
      newEmpresa: true,
      idEmpresa: 0
    })
  }

  editEmpresa(idEmpresa){
    this.setState({
      newEmpresa: true,
      idEmpresa: idEmpresa
    })
  }

  removeEmpresa(idEmpresa){
    this.setState({ loading: true})
    new TokenService().getToken()
    .then(res => {
      let token = res.data.access_token
      new EmpresaService(token).remove(idEmpresa)
      .then(res=>{
        this.setState({ loading: false})
        this.setState({
            modal: {
                showModal: true,
                title: 'Exclusão de Empresa',
                message: res.data.message
            },
        });
        this.search()
      })
      .catch(error=>{
        this.setState({ loading: false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({ loading: false})
      this.showError(error)
    })
  }

  activateEmpresa(idEmpresa){
    localStorage.setItem('empresa',idEmpresa);
    this.setState({
      empresaAtiva: idEmpresa
    })
  }

  handleFormCancel = () => {
    this.setState({
      newEmpresa:false
    })
  }

  handleFormSave = () => {
    this.setState({
      newEmpresa:false,
      modal : {
        showModal: true,
        title: 'Inclusão / Alteração de Empresa',
        message: 'Informação salva com sucesso!'
      }
    })
    this.search()
  }

  showFormEmpresa(){
    if(this.state.newEmpresa){
      return(
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 mx-auto">
            <FormEmpresa idEmpresa={this.state.idEmpresa} onCancel={this.handleFormCancel} onSave={this.handleFormSave}></FormEmpresa>
          </div>
        </div>
      )
    }
  }

  showListEmpresa(){
    if(!this.state.newEmpresa){
      return(
        <div>
          <div className="row">
            <div className="col-8 mx-auto">
              <InputGroup>
                <Input placeholder="Digite a razão social ou o cnpj da empresa ou o # da empresa que deseja encontrar" id="searchValue" name="searchValue" value={this.state.searchValue} onChange={this.handleChange}/>
                <InputGroupAddon addonType="prepend">
                  <Button color="primary" onClick={()=>this.search()}><i className="fas fa-search"></i></Button>
                  <Button color="danger" onClick={()=>this.cancel()}><i className="fas fa-ban"></i></Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
          <div className="row pageTitle">
            <div className="col-10 mx-auto">
              <Button color="primary" onClick={()=>this.createEmpresa()}><i className="fas fa-plus-circle"></i> Nova Empresa</Button>
              { this.drawTable() }
            </div>
          </div>
        </div>
      )
    }
  }

  render(){
    return(
      <Internal title="Empresas" loading={this.state.loading} showActivateEmpresa={this.state.showActivateEmpresa} empresaAtiva={this.state.empresaAtiva}>
        {this.showFormEmpresa()}
        {this.showListEmpresa() }
        <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
      </Internal>
    )
  }
}
