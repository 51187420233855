import React from 'react'
import Internal from '../Layouts/Internal'
import Generic from '../Generic'
import TokenService from '../../Services/TokenService'
import ModalAlert from '../Tools/ModalAlert'
import ObrigacaoService from '../../Services/ObrigacaoService'
import { Card, CardHeader,  CardBody,
  CardTitle, Button, CardColumns} from 'reactstrap';
import Obrigacao from './Obrigacao'

export default class Arquivos extends Generic {

  constructor(props){
    super(props)
    this.state = {
      loading: false,
      modal: {
          showModal: false,
          title: '',
          message: ''
      },
      obrigacoes: [],
      showActivateEmpresa: true,
      periodosFiscal: [],
      empresaAtiva: localStorage.getItem('empresa')
    }
  }

  componentWillMount(){
    this.setState({ loading: true})
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).getAll()
      .then(res=>{
        this.setState(
          {
            loading: false,
            obrigacoes: res.data.return_value
          }
        )
      })
      .catch(error=>{
        this.setState({ loading: false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({ loading: false})
      this.showError(error)
    })
  }

  drawBoxes(){
    if (this.state.empresaAtiva!==null){
      return (
        this.state.obrigacoes.map(obrigacao => {
          return (
            <div key={obrigacao.id} className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <Card>
              <CardHeader className="login" onClick={()=>{alert('Aqui')}}>
                { obrigacao.descricao }
              </CardHeader>
              <CardBody className="card_body_files">
                <Obrigacao id={obrigacao.id} name={obrigacao.nome} type={obrigacao.tipoArquivo} onLoaded={this.handleLoaded} history={this.props.history}/>
              </CardBody>
            </Card>
            </div>
          )
        })
      )
    } else {
      return (
        <div>
        </div>
      )
    }
  }

  handleLoaded = (status,message) => {
      this.setState({
          modal: {
              showModal: true,
              title: 'Carregamento de Obrigações',
              message: message
          }
      });
  }

  render(){
    return(
      <Internal title="Obrigações Fiscais" loading={this.state.loading} showActivateEmpresa={this.state.showActivateEmpresa}>
        <div className="row">
        { this.drawBoxes() }
        </div>
        <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
      </Internal>
    )
  }
}
