import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class ModalAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            title: '',
            message: ''
        };

        this.toggle = this.toggle.bind(this);
    }

    rawMarkup(){
        var rawMarkup = this.props.message
        return { __html: rawMarkup };
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
        this.props.onOk('1');
    }

    componentDidMount(){
        this.setState ({
            modal: this.props.isOpen,
            title: this.props.title,
            message: this.props.message
        });
    }

    componentDidUpdate(prevProps){
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState ({
                modal: this.props.isOpen,
                title: this.props.title,
                message: this.props.message
            });
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader className='custom-header' toggle={this.toggle}>{this.state.title}</ModalHeader>
                    <ModalBody className='custom-body'>
                        <span dangerouslySetInnerHTML={this.rawMarkup()} />
                    </ModalBody>
                    <ModalFooter className='custom-footer'>
                        <Button color="danger" className='btn-block' onClick={this.toggle}>Entendi</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
