import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import withRouter from 'react-router-dom/withRouter'
import Navigation from './Navigation'
import Loading from '../Tools/Loading'
import { Alert } from 'reactstrap';
import TokenService from '../../Services/TokenService'
import EmpresaService from '../../Services/EmpresaService'
import Generic from '../Generic'

class Internal extends Generic {

  constructor(props){
    super(props)
    this.state = {
      empresaAtiva:0,
      cnpj: '',
      razaoSocial: '',
      showActivateEmpresa: false,
    }
  }

  componentDidMount(){
    let user = localStorage.getItem(btoa('user'));
    if (user===null){
        this.props.history.push("/login");
    } else {
      var user1 = JSON.parse(atob(user));
      if (user1.primeiroAcesso === 1){
        this.props.history.push('/alterarsenha');
      }
    }
  }

  componentWillMount(){
    this.updateActiveEmpresa()
  }

  componentWillUpdate(prevProps){
    if (prevProps.empresaAtiva !== this.props.empresaAtiva){
      this.updateActiveEmpresa()
    }
  }

  updateActiveEmpresa(){
    let empresa = localStorage.getItem('empresa')
    if (empresa!==null){
      new TokenService().getToken()
      .then(res=>{
        let token = res.data.access_token
        new EmpresaService(token).get(empresa)
        .then(res => {
          this.setState({
            cnpj: res.data.return_value.CNPJ,
            razaoSocial: res.data.return_value.razaoSocial,
            empresaAtiva: empresa
          })
        })
        .catch(error => {
          this.showError(error)
        })
      })
      .catch(error => {
        this.showError(error)
      })
    } else {
      this.setState({
        showActivateEmpresa: this.props.showActivateEmpresa
      })
    }
  }

  showActiveEmpresa(){
    if (this.state.empresaAtiva!==0 && this.props.showActivateEmpresa){
        return(
          <Alert color="primary">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <strong><i>Empresa Ativa</i></strong>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <strong>CNPJ: </strong>{this.formatCnpj(this.state.cnpj)}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <strong>Razão Social: </strong>{this.state.razaoSocial}
              </div>
            </div>
          </Alert>
        )
    } else {
      if (this.state.showActivateEmpresa){
        return(
          <Alert color="primary">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <strong><i>Nenhuma empresa está ativa no momento.<br/>É necessário ativar uma empresa para poder carregar ou visualizar arquivos.<br/>Clique <Link to="/empresas">aqui</Link> para lista de empresas!</i></strong>
              </div>
            </div>
          </Alert>
        )
      }
    }
  }

  render(){
    return(
      <div>
        <Navigation />
        <div className="container-fluid content">
          <div className="row">
            <div className="col-12 text-center pageTitle">
              <h2>{ this.props.title }</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <br/>
              { this.showActiveEmpresa()}
            </div>
          </div>
          { this.props.children }
        </div>
        <Loading visible={this.props.loading}/>
      </div>
    )
  }

}

export default withRouter(Internal)
