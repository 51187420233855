import React from 'react'
import Generic from '../Generic'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';


export default class Paginator extends Generic {

    constructor(props){
        super(props)
    }

    firstPage(){
        this.props.onFirstPage()
    }

    previousPage(){
        this.props.onPreviousPage()
    }

    nextPage(){
        this.props.onNextPage()
    }

    lastPage(){
        this.props.onLastPage()
    }

    drawPagination(){
        return(
          <Pagination aria-label="Page navigation example">
            { this.drawFirstPage() }
            { this.drawPreviousPage() }
            <PaginationItem>
              <PaginationLink>
                {this.props.current_page}/{ this.props.last_page}
              </PaginationLink>
            </PaginationItem>
            { this.drawNextPage() }
            { this.drawLastPage() }
          </Pagination>
        )
    }

    drawLastPage(){
        var uicomponent = null
        if (this.props.current_page===this.props.last_page){
          uicomponent = <PaginationItem disabled>
            <PaginationLink onClick={()=>this.lastPage()}>
              Última
            </PaginationLink>
          </PaginationItem>
        } else {
          uicomponent = <PaginationItem>
            <PaginationLink onClick={()=>this.lastPage()}>
              Última
            </PaginationLink>
          </PaginationItem>
        }
    
        return uicomponent
    }
    
    drawNextPage(){
        var uicomponent = null
        if (this.props.current_page===this.props.last_page){
          uicomponent = <PaginationItem disabled>
            <PaginationLink onClick={()=>this.nextPage()}>
              Próxima
            </PaginationLink>
          </PaginationItem>
        } else {
          uicomponent = <PaginationItem>
            <PaginationLink onClick={()=>this.nextPage()}>
              Próxima
            </PaginationLink>
          </PaginationItem>
        }
    
        return uicomponent
    }
    
    drawFirstPage(){
        var uicomponent = null
        if (this.props.current_page===1){
          uicomponent = <PaginationItem disabled>
            <PaginationLink onClick={()=>this.firstPage()}>
              Primeira
            </PaginationLink>
          </PaginationItem>
        } else {
          uicomponent = <PaginationItem>
            <PaginationLink onClick={()=>this.firstPage()}>
              Primeira
            </PaginationLink>
          </PaginationItem>
        }
    
        return uicomponent
    }
    
    drawPreviousPage(){
        var uicomponent = null
        if (this.props.current_page===1){
          uicomponent = <PaginationItem disabled>
            <PaginationLink  onClick={()=>this.previousPage()}>
              Anterior
            </PaginationLink>
          </PaginationItem>
        } else {
          uicomponent = <PaginationItem>
            <PaginationLink  onClick={()=>this.previousPage()}>
              Anterior
            </PaginationLink>
          </PaginationItem>
        }
    
        return uicomponent
    }

    render(){
        return(
            <div>
                { this.drawPagination() }
            </div>
        )
    }
}