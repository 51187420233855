const dev = {
    OAUTH_TOKEN: 'http://localhost:8000/oauth/token',
    API_LOCATION: 'http://localhost:8000/api/',
    CLIENT_ID: '2',
    CLIENT_SECRET: 'OGNGObxxIkgEJx4tSy1099JU43Am12bW5OzfiuLe',
  };

  const prod = {
    OAUTH_TOKEN: 'https://api.inteligenciafiscaldigital.com.br/oauth/token',
    API_LOCATION: 'https://api.inteligenciafiscaldigital.com.br/api/',
    CLIENT_ID: '2',
    CLIENT_SECRET: 'OGNGObxxIkgEJx4tSy1099JU43Am12bW5OzfiuLe',
  };

  const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : dev;

  export default {
    // Add common config values here
    //MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };
