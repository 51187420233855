import React, { Component } from 'react'
import Internal from '../Layouts/Internal'

export default class Home extends Component {

  render(){
    return(
      <Internal>
      </Internal>
    )
  }
}
