import React from 'react'
import Generic from '../../Generic'
import Internal from '../../Layouts/Internal'
import { Button, FormGroup, Label, Input, Card,
    CardHeader,
    CardFooter,
    CardBody } from 'reactstrap'
import TokenService from '../../../Services/TokenService'
import ModalAlert from '../../Tools/ModalAlert'
import UserService from '../../../Services/UserService';


export default class ChangePassword extends Generic {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            modal: {
                showModal: false,
                title: '',
                message: ''
            },
            id: '',
            senha: '',
            senha_confirmation: '',
        }
    }

    componentDidMount(){
        window.scrollTo(0,0)
    }

    handleModalOk = () => {
        this.setState({
            modal: {
                showModal: false,
                title: '',
                message: ''
            }
        });
        this.props.history.push("/");
    }


    save(){
        let userstorage = localStorage.getItem(btoa('user'));
        let user = JSON.parse(atob(userstorage));
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            var body = {
                id: user.idUsuario,
                senha: this.state.senha,
                senha_confirmation: this.state.senha_confirmation
            }
            new UserService(token).changepassword(body)
            .then(res=>{
                localStorage.setItem(btoa('user'),btoa(JSON.stringify(res.data.return_value)));
                this.setState({
                    modal: {
                        showModal: true,
                        title: 'Alteração de Senha',
                        message: 'Senha alterada com sucesso!'
                    }
                });
            })
            .catch(error=>{
                this.showError(error)
            })
        })
        .catch(error => {
            this.showError(error)
        })
        
    }

    render(){
        return(
            <Internal title="Definição de Senha" loading={this.state.loading}>
                <Card>
                    <CardHeader className="internal">Alterar Senha</CardHeader>
                    <CardBody>
                        <FormGroup>
                            <Label for="senha"><strong>Senha</strong></Label>
                            <Input type="password" name="senha" id="senha" placeholder="Digite a senha" value={this.state.senha} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="senha_confirmation"><strong>Confirme a Senha</strong></Label>
                            <Input type="password" name="senha_confirmation" id="senha_confirmation" placeholder="Confirme a senha" value={this.state.senha_confirmation} onChange={this.handleChange}/>
                        </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Button color="primary" onClick={()=>this.save()}><i className="fas fa-hdd"></i> Alterar</Button>&nbsp;
                    </CardFooter>
                    <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
                </Card>
            </Internal>
        )
    }
}