import React from 'react'
import Generic from '../Generic'
import External from '../Layouts/External'
import ModalAlert from '../Tools/ModalAlert'
import { Card, Button, CardHeader, CardFooter, CardBody,
  CardTitle, FormGroup, Label, Input } from 'reactstrap';

import TokenService from '../../Services/TokenService'
import UserService from '../../Services/UserService'

import Cookies from 'universal-cookie'

export default class Login extends Generic {

 constructor(props){
    super(props)
    this.state = {
      login: '',
      senha: '',
      modal: {
          showModal: false,
          title: '',
          message: ''
      },
      loading: false,
      year: new Date().getFullYear()
    }
  }

  componentDidMount(){
    const cookies = new Cookies();
    if(cookies.get('newversion')==undefined){
      this.props.history.push("/newversion");
    }
  }

  authenticate(){
    this.setState({ loading: true})
    new TokenService().getToken()
    .then(res => {
      let token = res.data.access_token
      new UserService(token).authenticate(this.state.login,this.state.senha)
      .then(res => {
        this.setState({ loading: false})
        localStorage.setItem(btoa('user'),btoa(JSON.stringify(res.data.return_value)));
        this.props.history.push("/");
      })
      .catch(error => {
        this.setState({ loading: false})
        this.showError(error)
        this.setState({
          login: '',
          senha: ''
        })
      })
    })
    .catch(error => {
      this.setState({ loading: false})
      this.showError(error)
      this.setState({
        login: '',
        senha: ''
      })
    })
  }

  onKeyPress = (e) => {
      if(e.key === 'Enter'){
          this.authenticate();
      }
  }

  forgotPassword(){
    this.props.history.push("/esquecisenha");
  }

  render(){
    return(
      <External loading={this.state.loading}>
        <Card className="login">
          <CardHeader className="text-center login" tag="h2">Acesso ao Sistema</CardHeader>
          <CardBody>
              <FormGroup>
                <Label for="login">Usuário:</Label>
                <Input type="text" name="login" id="login" value={this.state.login} onChange={this.handleChange} placeholder="Digite seu login" onKeyPress={this.onKeyPress}/>
              </FormGroup>
              <FormGroup>
                <Label for="senha">Senha:</Label>
                <Input type="password" name="senha" id="senha" value={this.state.senha} onChange={this.handleChange} placeholder="Digite sua senha" onKeyPress={this.onKeyPress}/>
              </FormGroup>
            <Button color="primary" block onClick={() => this.authenticate()}><i className="fas fa-sign-in-alt"></i> Entrar</Button>{' '}
            <Button color="warning" block onClick={()=>this.forgotPassword()}><i className="fas fa-passport"></i> Esqueci a Senha</Button>
          </CardBody>
          <CardFooter className="text-right login">
            &copy; 2012-{ this.state.year } Taxview - Inteligência Fiscal Digital
          </CardFooter>
        </Card>
        <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
      </External>
    )
  }
}
