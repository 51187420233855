import React from 'react'
import Internal from '../Layouts/Internal'
import Generic from '../Generic';
import { Button, Table, Alert, Input, Label, FormGroup } from 'reactstrap'
import TokenService from '../../Services/TokenService';
import ModalAlert from '../Tools/ModalAlert'
import NfeService from '../../Services/NfeService';

export default class NfeLotes extends Generic {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            modal: {
                showModal: false,
                title: '',
                message: ''
              },
            nfes: [],
            idStatusProcessamento: ""
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    componentWillMount(){
        this.loadProcessingNfes();
    }

    loadProcessingNfes(){
        this.setState({
            loading:true
        })
        new TokenService().getToken()
        .then(res => {
            let token = res.data.access_token
            new NfeService(token).getNfeProcessar(localStorage.getItem('empresa'), this.state.idStatusProcessamento)
            .then(res => {
                console.log(res.data)
                this.setState({
                    nfes: res.data.return_value,
                    loading:false
                })
            })
            .catch (error => {
                this.setState({
                    loading:false
                })
                this.showError(error)
            })
        })
        .catch(error => {
            this.setState({
                loading:false
            })
            this.showError(error)
        })
    }

    viewNfeProcessadas(idNfeProcessar){
        this.props.history.push({
          pathname: "/nfes_processadas",
          search: '?idNfeProcessar='+idNfeProcessar
        })
      }

    drawTable(){
        if (this.state.nfes.length === 0){
            return (
                <Alert color="info">
                    <div className="text-center">Nenhum lote encontrado!</div>
                </Alert>
            )
        } else {
            return(
                <div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th style={{ textAlign:'center'}}>
                            Arquivo de Lote
                        </th>
                        <th style={{ textAlign:'center'}}>
                            Qtde Xmls no Arquivo
                        </th>
                        <th style={{ textAlign:'center'}}>
                            Qtde Xmls Processados
                        </th>
                        <th style={{ textAlign:'center'}}>
                            Status Processamento
                        </th>
                        <th style={{ textAlign:'center'}}>
                            Visualizar Lote
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.nfes.map(nfe=>{
                                var statusProcessamento = ""
                                switch (nfe.idStatusProcessamento) {
                                    case 0:
                                        statusProcessamento = "Para processar"
                                        break;
                                    case 1:
                                        statusProcessamento = "Em processamento"
                                        break;
                                    case 2:
                                        statusProcessamento = "Processado"
                                        break;
                                    case 3:
                                        statusProcessamento = "Processado parcialmente"
                                        break;
                                    default:
                                        break;
                                }
                                return(
                                    <tr key={nfe.idNfeProcessar}>
                                        <td style={{ textAlign:'center'}}>{ nfe.arquivo }</td>
                                        <td style={{ textAlign:'center'}}>{ nfe.qtdeXml }</td>
                                        <td style={{ textAlign:'center'}}>{ nfe.qtdeXmlProcessado }</td>
                                        <td style={{ textAlign:'center'}}>{ statusProcessamento }</td>
                                        <td style={{ textAlign:'center'}}>
                                            <a href="javascript:void(0);" onClick={()=>this.viewNfeProcessadas(nfe.idNfeProcessar)}><i className="far fa-eye"></i></a>
                                        </td>                              
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            )
        }
    }

    onFilter(e){
        this.setState({
            idStatusProcessamento: e.target.value
        })
        this.loadProcessingNfes()
    }

    filter(){
        return(
            <div>
                <br/>
                <FormGroup>
                    <Label for="filterBy"><strong>Filtrar Por:</strong></Label>
                    <Input type="select" name="idStatusProcessamento" id="filterBy" onChange={(event)=>this.onFilter(event)}>
                        <option value="">Todos</option>
                        <option value="0">Para processar</option>
                        <option value="1">Em processamento</option>
                        <option value="2">Processado</option>
                        <option value="3">Processado parcialmente</option>
                    </Input>
                </FormGroup>
            </div>
        )
    }

    render(){
        return(
            <Internal loading={this.state.loading} title="Lotes Nfes" showActivateEmpresa={true}>
                <div>
                    <Button color="primary" onClick={this.props.history.goBack}><i className="fas fa-backspace"></i> Voltar</Button>
                    { this.filter() }
                    { this.drawTable() }
                    <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
                </div>
            </Internal>
        )
    }
}