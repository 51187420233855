import React from 'react'
import Internal from '../Layouts/Internal'
import Generic from '../Generic';
import { Button, Table, Alert, FormGroup,Label,Input } from 'reactstrap'
import TokenService from '../../Services/TokenService'
import ModalAlert from '../Tools/ModalAlert'
import NfeService from '../../Services/NfeService'
import queryString from 'query-string'
import Paginator from '../Tools/Paginator'

export default class NfeProcessadas extends Generic {

  constructor(props){
      super(props)
      this.state = {
        loading:false,
        idNfeProcessar: 0,
        modal: {
            showModal: false,
            title: '',
            message: ''
          },
        nfes: [],
        current_page:1,
        last_page:1,
        idStatusProcessamento: ''
      }
  }

  componentWillMount(){
    const values = queryString.parse(this.props.location.search);
    this.setState({
      idNfeProcessar: values.idNfeProcessar,
      loading: true
    })

    this.loadNfes()
  }

  loadNfes(){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res => {
      let token = res.data.access_token
      new NfeService(token).getNfeProcessadas(this.state.idNfeProcessar,this.state.idStatusProcessamento,this.state.current_page)
      .then(res => {
        this.setState({
          loading:false
        })
        console.log(res)
        this.setState({
          nfes: res.data.return_value.data,
          current_page: res.data.return_value.current_page,
          last_page: res.data.return_value.last_page
        })
      })
      .catch(error=>{
        this.setState({
          loading:false
        })
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({
        loading:false
      })
      this.showError(error)
    })
  }

  firstPage = () => {
    this.setState({
      current_page: 1
    })
    this.loadNfes()
  }

  previousPage = () => {
    if (this.state.current_page>1){
      this.setState({
        current_page: this.state.current_page-1
      })
      this.loadNfes()
    }
  }

  nextPage = () => {
    if (this.state.current_page<this.state.last_page){
      this.setState({
        current_page: this.state.current_page+1
      })
      this.loadNfes()
    }
  }

  lastPage = () => {
    this.setState({
        current_page: this.state.last_page
    })
    this.loadNfes()
  }

  drawTable(){
    if (this.state.nfes.length === 0){
      return (
          <Alert color="info">
              <div className="text-center">Nenhuma NFe foi encontrada!</div>
          </Alert>
      )
    } else {
      return(
        <div>
          <br/>
          <Paginator last_page={this.state.last_page} current_page={this.state.current_page} onFirstPage={this.firstPage} onNextPage={this.nextPage} onPreviousPage={this.previousPage} onLastPage={this.lastPage}/>
          <Table responsive>
            <thead>
            <tr>
                <th style={{ width: "40%"}}>
                    XML
                </th>
                <th style={{ width: "20%"}}>
                    Status
                </th>
                <th style={{ width: "40%"}}>
                    Mensagem de Processamento
                </th>
            </tr>
            </thead>
            <tbody>
                {
                    this.state.nfes.map(nfe=>{
                        var statusProcessamento = ""
                        switch (nfe.statusProcessamento) {
                            case 1:
                                statusProcessamento = "Processada"
                                break;
                            case 0:
                                statusProcessamento = "Não processada"
                                break;
                            default:
                                break;
                        }
                        return(
                            <tr>
                                <td>{ nfe.arquivoNfe }</td>
                                <td>{ statusProcessamento }</td>
                                <td>{ nfe.mensagem }</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
        </div>
      )
    }
  }

  onFilter(e){
    this.setState({
        idStatusProcessamento: e.target.value,
        current_page: 1
    })
    this.loadNfes()
}

filter(){
    return(
        <div>
            <br/>
            <FormGroup>
                <Label for="filterBy"><strong>Filtrar Por:</strong></Label>
                <Input type="select" name="idStatusProcessamento" id="filterBy" onChange={(event)=>this.onFilter(event)}>
                    <option value="">Todos</option>
                    <option value="1">Processada</option>
                    <option value="0">Não processada</option>
                </Input>
            </FormGroup>
        </div>
    )
}

  render(){
    return(
      <Internal title="Nfes Processadas por Lote" loading={this.state.loading} showActivateEmpresa={true}>
        <div>
            <Button color="primary" onClick={this.props.history.goBack}><i className="fas fa-backspace"></i> Voltar</Button>
            { this.filter() }
            { this.drawTable() }
            <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
        </div>
      </Internal>
    )
  }

}