import React from 'react'
import Generic from '../Generic'
import { Button, FormGroup, Label, Input, Card,
    CardHeader,
    CardFooter,
    CardBody } from 'reactstrap'
import MaskedInput from 'react-maskedinput'
import TokenService from '../../Services/TokenService'
import EmpresaService from '../../Services/EmpresaService'
import ModalAlert from '../Tools/ModalAlert'
import Loading from '../Tools/Loading'

export default class FormEmpresa extends Generic {

    constructor(props){
        super(props)
        this.state = {
            id: 0,
            loading: false,
            modal: {
                showModal: false,
                title: '',
                message: ''
            },
            cnpj: '',
            razaoSocial: ''
        }
    }

    componentDidMount(){
        if (this.props.idEmpresa!==0){
            this.setState({loading:true})
            new TokenService().getToken()
            .then(res=>{
                let token = res.data.access_token
                new EmpresaService(token).get(this.props.idEmpresa)
                .then(res => {
                    this.setState({
                        loading:false,
                        cnpj: res.data.return_value.CNPJ,
                        razaoSocial: res.data.return_value.razaoSocial,
                        id: res.data.return_value.idEmpresa
                    })
                })
                .catch(error => {
                    this.setState({loading:false})
                })
            })
            .catch(error=>{
                this.setState({loading:false})
                this.showError(error.response.status, error.response)
            })
        } else {
            this.setState({
                cnpj: '',
                razaoSocial:'',
                id: 0
            })
        }
    }

    inputCNPJ(){
        if(this.state.id === 0){
          return <MaskedInput className="form-control" mask="11.111.111/1111-11" name="cnpj" id="cnpj" value={this.state.cnpj} onChange={this.handleChange} placeholder="00.000.000/0000-00"/>
        } else {
          return <MaskedInput disabled className="form-control" mask="11.111.111/1111-11" name="cnpj" id="cnpj" value={this.state.cnpj} onChange={this.handleChange} placeholder="00.000.000/0000-00"/>
        }
    }

    cancel(){
        this.props.onCancel()
    }

    save(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res => {
            let token = res.data.access_token
            new EmpresaService(token).save(this.state.id,this.state.cnpj,this.state.razaoSocial,this.globalUser.idUsuario)
            .then(res => {
                this.setState({loading:false})
                this.props.onSave()
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({loading:false})
            this.showError(error)
        })
    }

    render(){
        return(
            <div>
                <Card>
                    <CardHeader className="login">Cadastrar / Editar Empresa</CardHeader>
                    <CardBody>
                        <FormGroup>
                            <Label for="cnpj">CNPJ:</Label>
                            { this.inputCNPJ() }
                        </FormGroup>
                        <FormGroup>
                            <Label for="razaoSocial">Razão Social:</Label>
                            <Input name="razaoSocial" id="razaoSocial" value={this.state.razaoSocial} onChange={this.handleChange} placeholder="Digite a razão social"/>
                        </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Button color="primary" onClick={()=>this.save()}><i className="fas fa-hdd"></i> Salvar</Button>&nbsp;
                        <Button color="danger" onClick={()=>this.cancel()}><i className="fas fa-ban"></i> Cancelar</Button>
                    </CardFooter>
                    <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
                    <Loading visible={this.state.loading}/>
                </Card>
            </div>
        )
    }
}