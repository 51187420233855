import React from 'react'
import Generic from '../../Generic'
import Internal from '../../Layouts/Internal'
import ModalAlert from '../../Tools/ModalAlert'
import TokenService from '../../../Services/TokenService';
import CrossingReportsService from '../../../Services/CrossingReportsService';

import { FormGroup, Input, Label, Row, Col, Table, Button } from 'reactstrap'
import MaskedInput from 'react-maskedinput'
import EmpresaService from '../../../Services/EmpresaService';

export default class Crossing extends Generic {
    constructor(props){
        super(props)
        this.state = {
            loading:false,
            modal: {
                showModal:false,
                title:'',
                message: ''
            },
            reports:[],
            period: new Date().getFullYear(),
            company:"",
            report: 0,
            minYear: (new Date().getFullYear())-10,
            maxYear: (new Date().getFullYear())+10,
            infos: []
        }
    }

    componentWillMount(){
        if(localStorage.getItem('empresa')!==null){
            this.loadCompany()
            this.loadReports()
        }
    }

    loadCompany(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            new EmpresaService(token).get(localStorage.getItem('empresa'))
            .then(res=>{
                this.setState({
                    loading:false,
                    company: res.data.return_value.CNPJ
                })
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({loading:false})
            this.showError(error)
        })
    }

    loadReports(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            new CrossingReportsService(token).getReports()
            .then(res => {
                this.setState({
                    loading:false,
                    reports: res.data.return_value
                })
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({loading:false})
            this.showError(error)
        })
    }

    choosePeriod(e){
        this.setState({
            period: e.target.value
        })
    }

    chooseReport(e){
        this.setState({
            report: e.target.value
        })
    }

    drawYearOption(){
        var options = []
        for (let index = this.state.minYear; index < this.state.maxYear; index++) {
            options.push(<option value={index} key={index}>{index}</option>)
        }
        return (
            <Input type="select" name="period" id="period" value={this.state.period} onChange={(event)=>this.choosePeriod(event)}>
                <option value="0">Selecione um período</option>
                { options }
            </Input>
        )
    }

    drawFilter(){
        if(localStorage.getItem('empresa')!==null){
            return(
                <div>
                    <Row>
                        <Col xs="12" sm="12" md="4" lg="4">
                            <FormGroup>
                                <Label for="period"><strong>Ano</strong></Label>
                                {this.drawYearOption()}
                            </FormGroup>
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4">
                            <FormGroup>
                                <Label for="company"><strong>CNPJ Empresa ou Filial</strong></Label>
                                <MaskedInput className="form-control" mask="11.111.111/1111-11" name="company" id="company" value={this.state.company} onChange={this.handleChange} placeholder="00.000.000/0000-00"/>
                            </FormGroup>
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4">
                            <FormGroup>
                                <Label for="report"><strong>Relatório</strong></Label>
                                <Input type="select" name="report" id="report" value={this.state.report} onChange={(event)=>this.chooseReport(event)}>
                                    <option value="0">Selecione um relatório</option>
                                    {
                                        this.state.reports.map(report => {
                                            return(
                                                <option value={report.idRelatorioCruzamento} key={report.idRelatorioCruzamento}>{report.nome}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="primary" onClick={()=>this.verifyReport()}><i className="fas fa-clipboard-check"></i> Verificar Relatório</Button>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return(
                <div></div>
            )
        }
    }

    generateReport(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            new CrossingReportsService(token).generateReport(this.state.company,this.state.period,this.state.report,this.globalUser.idUsuario,localStorage.getItem('empresa'))
            .then(res=>{
                let filename = res.data.return_value
                var message = "<div class='text-center'>Para baixar o arquivo, clique no ícone abaixo:</div><br/>"
                message = message + "<div class='text-center'><a href='"+filename+"'><i class='fas fa-file-excel fa-3x'></i></a></div>"
                this.setState({
                    loading:false,
                    modal: {
                        showModal: true,
                        title: 'Arquivo gerado com sucesso',
                        message: message
                    }
                });
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({loading:false})
            this.showError(error)
        })
    }

    verifyReport(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            new CrossingReportsService(token).returnInformationTable(this.state.company,this.state.period,this.state.report,this.globalUser.idUsuario,localStorage.getItem('empresa'))
            .then(res => {
                this.setState({
                    loading:false,
                    infos: res.data.return_value
                })
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({loading:false})
            this.showError(error)
        })
    }

    drawInfoTable(){
        if(this.state.infos.length == 0){
            return(
                <div></div>
            )
        } else {
            return(
                <div>
                    <br/>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th colSpan='13'>
                                    { this.state.period }
                                </th>
                            </tr>
                            <tr>
                                <th>Arquivos / Meses </th>
                                <th>JAN</th>
                                <th>FEV</th>
                                <th>MAR</th>
                                <th>ABR</th>
                                <th>MAI</th>
                                <th>JUN</th>
                                <th>JUL</th>
                                <th>AGO</th>
                                <th>SET</th>
                                <th>OUT</th>
                                <th>NOV</th>
                                <th>DEZ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.infos.map(info => {
                                    return(
                                        <tr>
                                            <td>{ info.file }</td>
                                            {
                                                info.months.map(month => {
                                                    return(
                                                        <td>
                                                            <i className="fas fa-check-circle" style={{ display: month.exist ? 'inline' : 'none' }}></i>
                                                            <i className="far fa-times-circle" style={{ display: !month.exist ? 'inline' : 'none' }}></i>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Row>
                        <Col>
                            <Button color="primary" onClick={()=>this.generateReport()}><i className="fas fa-chalkboard-teacher"></i> Gerar Relatório</Button>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    render(){
        return(
            <Internal title="Relatórios de Cruzamento" loading={this.state.loading} showActivateEmpresa={true}>
                { this.drawFilter() }
                { this.drawInfoTable() }
                <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
            </Internal>
        )
    }
}