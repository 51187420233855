import React, { Component } from 'react'
import Loading from '../Tools/Loading'
import withRouter from 'react-router-dom/withRouter'

class External extends Component {

  constructor(props){
    super(props)
  }

  componentWillMount(){
    let user = localStorage.getItem(btoa('user'));
    if (user!==null){
        this.props.history.push("/");
    }
  }

  render(){
    return(
      <div className='h-100 background'>
      <div className='background-transparent'></div>
        <div className="container-fluid h-100">
            <div className="row align-items-center h-100">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
                    { this.props.children }
                </div>
            </div>
            <Loading visible={this.props.loading}/>
        </div>
      </div>
    )
  }

}

export default withRouter(External)
