import React from 'react'
import Generic from '../../Generic'
import { Button, FormGroup, Label, Input, Card,
    CardHeader,
    CardFooter,
    CardBody } from 'reactstrap'
import TokenService from '../../../Services/TokenService'
import EmpresaService from '../../../Services/UserService'
import ModalAlert from '../../Tools/ModalAlert'
import Loading from '../../Tools/Loading'
import MaskedInput from 'react-maskedinput'
import UserService from '../../../Services/UserService';


export default class Form extends Generic {

    constructor(props){
        super(props)
        this.state = {
            id: 0,
            loading: false,
            modal: {
                showModal: false,
                title: '',
                message: ''
            },
            login: '',
            nome: '',
            email: '',
            senha: '',
            senha_confirmation: '',
            administrador: 0,
            primeiroAcesso: 0,
            dataExpiracao: '',
            qtdeEmpresasPermitidas: 0
        }
    }

    componentDidMount(){
        window.scrollTo(0,0)
        if (this.props.id > 0){
            this.getUser()
        }
    }

    getUser(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res => {
            let token = res.data.access_token
            new UserService(token).get(this.props.id)
            .then(res => {
                var dataExpiracao = ''
                if (res.data.return_value.dataExpiracao !== null){
                    let array1 = res.data.return_value.dataExpiracao.split(' ')
                    let array2 = array1[0].split('-')
                    dataExpiracao = array2[2]+"/"+array2[1]+"/"+array2[0]
                }

                this.setState(
                    {
                        loading:false,
                        id: res.data.return_value.idUsuario,
                        login: res.data.return_value.login,
                        nome: res.data.return_value.nome,
                        email: res.data.return_value.email,
                        administrador: res.data.return_value.administrador,
                        primeiroAcesso: res.data.return_value.primeiroAcesso,
                        dataExpiracao: dataExpiracao,
                        qtdeEmpresasPermitidas: res.data.return_value.qtdeEmpresasPermitidas
                    }
                )
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error => {
            this.setState({loading:false})
            this.showError(error)
        })
    }


    cancel(){
        this.props.onCancel()
    }

    save(){
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            var body = {
                id: this.state.id,
                login: this.state.login,
                nome: this.state.nome,
                email: this.state.email,
                senha: this.state.senha,
                senha_confirmation: this.state.senha_confirmation,
                administrador: this.state.administrador,
                primeiroAcesso: this.state.primeiroAcesso,
                dataExpiracao: this.state.dataExpiracao,
                qtdeEmpresasPermitidas: this.state.qtdeEmpresasPermitidas
            }
            new UserService(token).save(body)
            .then(res=>{
                this.props.onSave()
            })
            .catch(error=>{
                this.showError(error)
            })
        })
        .catch(error => {
            this.showError(error)
        })
        
    }

    render(){
        return(
            <div>
                <Card>
                    <CardHeader className="internal">Cadastrar / Editar Usuário</CardHeader>
                    <CardBody>
                        <FormGroup>
                            <Label for="login"><strong>Login</strong></Label>
                            <Input name="login" id="login" placeholder="Digite um login para usuário" value={this.state.login} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="nome"><strong>Nome</strong></Label>
                            <Input name="nome" id="nome" placeholder="Digite o nome do usuário"value={this.state.nome} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email"><strong>E-mail</strong></Label>
                            <Input type="email" name="email" id="email" placeholder="Digite o email do usuário" value={this.state.email} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="senha"><strong>Senha</strong></Label>
                            <Input type="password" name="senha" id="senha" placeholder="Digite uma senha para o usuário" value={this.state.senha} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="senha_confirmation"><strong>Confirme a Senha</strong></Label>
                            <Input type="password" name="senha_confirmation" id="senha_confirmation" placeholder="Confirme a senha para o usuário" value={this.state.senha_confirmation} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="administrador"><strong>Administrador?</strong></Label>
                            <Input type="select" name="administrador" id="administrador" value={this.state.administrador} onChange={this.handleChange}>
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="primeiroAcesso"><strong>Primeiro Acesso?</strong></Label>
                            <Input type="select" name="primeiroAcesso" id="primeiroAcesso" value={this.state.primeiroAcesso} onChange={this.handleChange}>
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="qtdeEmpresasPermitidas"><strong>Quantidade de Empresas Permitidas</strong></Label>
                            <Input type="number" name="qtdeEmpresasPermitidas" id="qtdeEmpresasPermitidas" value={this.state.qtdeEmpresasPermitidas} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="dataExpiracao"><strong>Acesso expira em?</strong></Label>
                            <MaskedInput className="form-control" mask="11/11/1111" name="dataExpiracao" id="dataExpiracao" value={this.state.dataExpiracao} onChange={this.handleChange} placeholder="dd/mm/aaaa"/>
                        </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Button color="primary" onClick={()=>this.save()}><i className="fas fa-hdd"></i> Salvar</Button>&nbsp;
                        <Button color="danger" onClick={()=>this.cancel()}><i className="fas fa-ban"></i> Cancelar</Button>
                    </CardFooter>
                    <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
                    <Loading visible={this.state.loading}/>
                </Card>
            </div>
        )
    }
}