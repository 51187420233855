import axios from 'axios';
import config from '../config';

export default class ObrigacaoService {

    token = undefined;

    constructor(token){
        this.token = token;
    }

    base(){
        return axios.create({
            baseURL: config.API_LOCATION,
            headers: {
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.token,
                'Content-Language': 'pt_BR',
                'Accept': 'application/json'
            }
          });
    }

    get(id){
      return this.base().get('/obrigacao/'+id);
    }

    getAll(){
      return this.base().get('/obrigacoes')
    }

    getPeriodos(idObrigacao,idUsuario,idEmpresa){
      var body = {
        id: idObrigacao,
        idUsuario: idUsuario,
        idEmpresa: idEmpresa
      }
      return this.base().post('obrigacoes/getPeriodos',body)
    }

    getArquivosPeriodo(idObrigacao,idUsuario,idEmpresa,year){
      var body = {
        id: idObrigacao,
        idUsuario: idUsuario,
        idEmpresa: idEmpresa,
        year: year
      }
      return this.base().post('obrigacoes/getArquivosPeriodo',body)
    }

    getHeader(idFile,idObrigacao){
      return this.base().get('obrigacoes/getHeader?idFile='+idFile+'&idObrigacao='+idObrigacao)
    }

    registerByObligationOnlyFile(idFile,idObrigacao){
      return this.base().get('obrigacoes/registerByObligationOnlyFile?idFile='+idFile+'&idObrigacao='+idObrigacao)
    }

    registerByObligationAll(idFile,idObrigacao){
      return this.base().get('obrigacoes/registerByObligationAll?idFile='+idFile+'&idObrigacao='+idObrigacao)
    }

    openRegister(idFile,idObrigacao,registerName,page){
      return this.base().get('obrigacoes/openRegister?page='+page+'&idFile='+idFile+"&idObrigacao="+idObrigacao+"&registerName="+registerName)
    }

    exportRegisterExcel(idFile,idObrigacao,registerName){
      return this.base().get('obrigacoes/exportRegisterExcel?idFile='+idFile+"&idObrigacao="+idObrigacao+"&registerName="+registerName)
    }

    exportMultipleRegisterExcel(idFile,idObrigacao){
      return this.base().get('obrigacoes/exportMultipleRegisterExcel?idFile='+idFile+"&idObrigacao="+idObrigacao)
    }

    remove(idFile,idObrigacao){
      var body = {
        idFile: idFile,
        idObrigacao: idObrigacao
      }
      return this.base().post('obrigacoes/remove',body)
    }
}
