import React from 'react'
import Internal from '../Layouts/Internal'
import Generic from '../Generic'
import { Button, Table, Alert,UncontrolledTooltip } from 'reactstrap'
import queryString from 'query-string';
import TokenService from '../../Services/TokenService'
import ObrigacaoService from '../../Services/ObrigacaoService'
import Paginator from '../Tools/Paginator'
import ModalAlert from '../Tools/ModalAlert'
import Nfes from '../Arquivos/Nfes'

export default class ObrigacaoDetails extends Generic {
  constructor(props){
    super(props)
    this.state = {
      type: 0,
      year: 0,
      idFile: 0,
      files:[],
      obligation:'',
      showDetails: 0, /* 0-No details; 1-All Register; 2-Register Name */
      header:null,
      details:[],
      loading: false,
      registerName:'',
      registerColumns:[],
      registerValues: [],
      current_page:1,
      last_page:1,
      modal: {
        showModal: false,
        title: '',
        message: ''
      },
    }
  }

  componentWillMount(){
    this.getPeriodFiles();
  }

  getPeriodFiles(){
    const values = queryString.parse(this.props.location.search);
    this.setState({
      type: values.type,
      year: values.year,
      loading:true
    })
    let type = values.type
    let year = values.year

    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).getArquivosPeriodo(type,this.globalUser.idUsuario,localStorage.getItem('empresa'),year)
      .then(res=>{
        console.log(res)
        this.setState({
          files: res.data.return_value
        })
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })

      new ObrigacaoService(token).get(type)
      .then(res=>{
        this.setState({
          obligation: res.data.return_value,
          loading:false
        })
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      console.log(error)
      this.showError(error)
    })
  }

  openDetail(registerName){
    if (registerName!==''){
      this.setState({
        registerName: registerName
      })
    }
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).openRegister(this.state.idFile,this.state.type,this.state.registerName,this.state.current_page)
      .then(res=>{
        //console.log(res)
        this.setState({
          showDetails:2,
          registerColumns: res.data.return_value.columns,
          registerValues: res.data.return_value.values.data,
          current_page: res.data.return_value.values.current_page,
          last_page: res.data.return_value.values.last_page,
          loading:false
        })
        window.scrollTo(0, 0)
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      this.showError(error)
    })
  }

  exportExcel(registerName){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).exportRegisterExcel(this.state.idFile,this.state.type,registerName)
      .then(res=>{
        console.log(res)
        let filename = res.data.return_value
        var message = "<div class='text-center'>Para baixar o arquivo, clique no ícone abaixo:</div><br/>"
        message = message + "<div class='text-center'><a href='"+filename+"'><i class='fas fa-file-excel fa-3x'></i></a></div>"
        this.setState({
          loading:false,
          modal: {
              showModal: true,
              title: 'Arquivo gerado com sucesso',
              message: message
          }
        });
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      this.showError(error)
    })
  }

  exportMultipleExcel(){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).exportMultipleRegisterExcel(this.state.idFile,this.state.type)
      .then(res=>{
        console.log(res)
        let filename = res.data.return_value
        var message = "<div class='text-center'>Para baixar o arquivo, clique no ícone abaixo:</div><br/>"
        message = message + "<div class='text-center'><a href='"+filename+"'><i class='fas fa-file-excel fa-3x'></i></a></div>"
        this.setState({
          loading:false,
          modal: {
              showModal: true,
              title: 'Arquivo gerado com sucesso',
              message: message
          }
      });
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      this.showError(error)
    })
  }

  removeFile(id){
    this.setState({
      idFile: id,
      loading: true,
    })
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).remove(this.state.idFile,this.state.type)
      .then(res=>{
        this.setState({
          loading:false,
          modal: {
              showModal: true,
              title: 'Exclusão de arquivo.',
              message: res.data.message
          }
        })
        this.getPeriodFiles()
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      this.showError(error)
    })
  }

  openFile(id){
    this.setState({
      idFile: id,
      loading:true,
    })
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).getHeader(this.state.idFile,this.state.type)
      .then(res=>{
        this.setState({
          showDetails:1,
          header:res.data.return_value,
          loading:false
        })
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      this.showError(error)
    })
    this.getOnlyFileRegisters();
  }

  getOnlyFileRegisters(){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).registerByObligationOnlyFile(this.state.idFile,this.state.type)
      .then(res=>{
        console.log(res.data)
        this.setState({
          details:res.data.return_value,
          loading:false,
        })
        window.scrollTo(0, 0)
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      this.showError(error)
    })
  }

  getAllRegisters(){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).registerByObligationAll(this.state.idFile,this.state.type)
      .then(res=>{
        this.setState({
          details:res.data.return_value,
          loading:false,
        })
        window.scrollTo(0, 0)
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      this.showError(error)
    })
  }

  drawTableObrigacoes(){
    return(
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th style={{width: '55%'}}>Empresa</th>
              <th>Período</th>
              <th style={{width: '15%'}}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.files.map(file => {
                  return (
                    <tr key={file.id}>
                      <td>{ file.MATRIZFILIAL==='0001' ? "Matriz-"+file.MATRIZFILIAL : "Filial-"+file.MATRIZFILIAL }</td>
                      <td>{ this.numberMonth2Text(file.MES*1) }/{file.ANO}</td>
                      <td>
                        <a href="javascript:void(0)" id={'openFile'+file.id} alt="" onClick={()=>this.openFile(file.id)}><i className="fas fa-folder-open"></i></a>&nbsp;
                        <UncontrolledTooltip placement="left" target={'openFile'+file.id}>
                          Registros do arquivo
                        </UncontrolledTooltip>
                        <a href="javascript:void(0)" id={'removeFile'+file.id} alt="" onClick={()=>{ if (window.confirm('Tem certeza que deseja excluir este arquivo?')) this.removeFile(file.id) }}><i className="fas fa-trash-alt"></i></a>
                        <UncontrolledTooltip placement="left" target={'removeFile'+file.id}>
                          Excluir o arquivo
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  )
              })
            }
          </tbody>
        </Table>
    </div>
    )
  }

  drawTableNfes(){
    return(
      <Nfes year={this.state.year} files={this.state.files}></Nfes>
    )
  }

  drawTableGia(){
    return(
      <h2>Teste</h2>
    )
  }

  drawTable(){
    switch (this.state.type) {
      case '10':
        return this.drawTableNfes()
      default:
        return this.drawTableObrigacoes()
    }
  }

  drawDetailHeader(){
    return(
      <Alert color="light">
        <div className="row">
          <div className="col-12">
            <strong>Empresa: </strong>{this.state.header.empresa}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <strong>CNPJ: </strong>{this.formatCnpj(this.state.header.cnpj)}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <strong>Versão do Arquivo: </strong>{this.state.header.codVer}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <strong>Data de Início: </strong>{this.formatDate(this.state.header.dataInicial)}
          </div>
          <div className="col-6">
            <strong>Data de Término: </strong>{this.formatDate(this.state.header.dataFinal)}
          </div>
        </div>
      </Alert>
    )
  }

  drawDetailButtons(){
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Button color="primary" onClick={()=>this.exportMultipleExcel()} ><i className="fas fa-file-excel"></i> Excel - Várias Planilhas</Button>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className="float-right">
            <Button color="primary" onClick={()=>this.getAllRegisters()}><i className="fas fa-copy"></i> Todos os Registros</Button>
            <Button color="primary" onClick={()=>this.getOnlyFileRegisters()}><i className="fas fa-file"></i> Somente do arquivo</Button>
          </div>
        </div>
      </div>
    )
  }

  drawDetailTable(){
    return (
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width:'15%'}}>Bloco de Registros</th>
              <th style={{ width:'60%'}}>Descrição</th>
              <th style={{ width:'20%'}}>Quantidade de Registros</th>
              <th style={{ width:'5%'}}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.details.map(detail => {
                  return (
                    <tr key={detail.REGISTRO}>
                      <td>{ detail.REGISTRO }</td>
                      <td>{ detail.DESCRICAO }</td>
                      <td>{ detail.QTD_REG_BLC }</td>
                      <td>
                        <a href="javascript:void(0)" alt="" id={'openRegister'+detail.REGISTRO} style={{ display: detail.QTD_REG_BLC!==0 ? 'inline' : 'none' }} onClick={()=>this.openDetail(detail.REGISTRO)}><i className="fas fa-folder-open"></i>&nbsp;</a>
                        <UncontrolledTooltip placement="left" target={'openRegister'+detail.REGISTRO}>
                          Visualizar Registro {detail.REGISTRO}
                        </UncontrolledTooltip>
                        <a href="javascript:void(0)" alt="" id={'exportRegister'+detail.REGISTRO} style={{ display: detail.QTD_REG_BLC!==0 ? 'inline' : 'none' }} onClick={()=>this.exportExcel(detail.REGISTRO)}><i className="fas fa-file-export"></i></a>
                        <UncontrolledTooltip placement="left" target={'exportRegister'+detail.REGISTRO}>
                          Exportar Registro {detail.REGISTRO}
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }

  firstPage = () => {
    this.setState({
      current_page: 1
    })
    this.openDetail('')
  }

  previousPage = () => {
    if (this.state.current_page>1){
      this.setState({
        current_page: this.state.current_page-1
      })
      this.openDetail('')
    }
  }

  nextPage = () => {
    if (this.state.current_page<this.state.last_page){
      this.setState({
        current_page: this.state.current_page+1
      })
      this.openDetail('')
    }
  }

  lastPage = () => {
    this.setState({
        current_page: this.state.last_page
    })
    this.openDetail('')
  }

  drawRegisterTable(){
    var columns = []
    for (let index = 0; index < this.state.registerColumns.length; index++) {
      const column = this.state.registerColumns[index];
      if (column.startsWith('id')){
        column = "#"
      }
      columns.push(<th key={index}>{ column }</th>)   
    }
    return (
      <div>
        <Paginator last_page={this.state.last_page} current_page={this.state.current_page} onFirstPage={this.firstPage} onNextPage={this.nextPage} onPreviousPage={this.previousPage} onLastPage={this.lastPage}/>
        <Table responsive>
          <thead>
            <tr>
              { columns }
            </tr>
          </thead>
          <tbody>
            {
              this.state.registerValues.map((row,i) => {
                var rows = []
                for (let index = 0; index < this.state.registerColumns.length; index++) {
                  const column = this.state.registerColumns[index];
                  var variable = "row."+column
                  rows.push(<td key={index}>{ eval(variable) }</td>)
                }
                return(
                  <tr key={i}>
                    {rows}
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
        <Paginator last_page={this.state.last_page} current_page={this.state.current_page} onFirstPage={this.firstPage} onNextPage={this.nextPage} onPreviousPage={this.previousPage} onLastPage={this.lastPage}/>
      </div>
    )
  }

  backFiles(){
    this.setState({
      showDetails:this.state.showDetails-1
    })
  }

  drawBody(){

    switch (this.state.showDetails) {
      case 0:
        return (
          <div>
            <Button color="primary" onClick={this.props.history.goBack}><i className="fas fa-backspace"></i> Voltar</Button>
            { this.drawTable() }
          </div>
        )
      case 1:
        return (
          <div>
            <Button color="primary" onClick={()=>this.backFiles()}><i className="fas fa-backspace"></i> Voltar</Button>
            { this.drawDetailHeader() }
            { this.drawDetailButtons() }
            { this.drawDetailTable() }
          </div>
        )
      case 2:
        return (
          <div>
            <Button color="primary" onClick={()=>this.backFiles()}><i className="fas fa-backspace"></i> Voltar</Button>
            { this.drawDetailHeader() }
            { this.drawRegisterTable() }
          </div>
        )
    }
  }

  render(){
    return(
      <Internal title="Obrigações Fiscais - Detalhes" loading={this.state.loading} showActivateEmpresa={true}>
        <h3 className="text-center">{ this.state.obligation.descricao }</h3>
        { this.drawBody() }
        <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
      </Internal>
    )
  }
}
