import axios from 'axios';
import config from '../config';

export default class AnalyticsReportsTypeService {

    token = undefined;

    constructor(token){
        this.token = token;
    }

    base(){
        return axios.create({
            baseURL: config.API_LOCATION,
            headers: {
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.token,
                'Content-Language': 'pt_BR',
                'Accept': 'application/json'
            }
          });
    }

    list(){
	    return this.base().get('analyticreportstype');
    }

}
