import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';

/* Components */
import Notfound from './Components/Notfound'
import Home from './Components/Home/Home'
import Login from './Components/Login/Login'
import Empresas from './Components/Empresas/Empresas'
import Arquivos from './Components/Arquivos/Arquivos'
import ObrigacaoDetails from './Components/Arquivos/ObrigacaoDetails'
import NfeProcessar from './Components/Nfes/NfeProcessar';
import NfeLotes from './Components/Nfes/NfeLotes';
import NfeProcessadas from './Components/Nfes/NfeProcessadas';
import NfeDetails from './Components/Nfes/NfeDetails';
import Settings from './Components/Administration/Settings';
import Users from './Components/Administration/Users/Users';
import ChangePassword from './Components/Administration/Users/ChangePassword'
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import RedefinePassword from './Components/RedefinePassword/RedefinePassword'
import Analyze from './Components/Reports/Analyze/Analyze';
import Crossing from './Components/Reports/Crossing/Crossing';
import Newversion from './Components/Newversion/Newversion';

const Rotas = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={Empresas} exact />
      <Route path="/home" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/newversion" component={Newversion}/>
      <Route path="/empresas" component={Empresas} />
      <Route path="/arquivos" component={Arquivos} />
      <Route path="/arquivos_detalhes" component={ObrigacaoDetails}/>
      <Route path="/nfes_processar" component={NfeProcessar}/>
      <Route path="/nfes_lotes" component={NfeLotes}/>
      <Route path="/nfes_processadas" component={NfeProcessadas}/>
      <Route path="/nfe_detalhes" component={NfeDetails}/>
      <Route path="/configuracoes" component={Settings}/>
      <Route path="/usuarios" component={Users}/>
      <Route path="/alterarsenha" component={ChangePassword}/>
      <Route path="/esquecisenha" component={ ForgotPassword }/>
      <Route path="/redefinirSenha" component={ RedefinePassword }/>
      <Route path="/relatorios/analiticos" component={ Analyze }/>
      <Route path="/relatorios/cruzamento" component = { Crossing }/>
      <Route component={Notfound} />
    </Switch>
  </BrowserRouter>
)

class App extends Component {

  

  render() {
    return (
      <div className='h-100'>
        <Rotas/>
      </div>
    );
  }
}

export default App;
