import React, { Component } from 'react'
import Internal from './Layouts/Internal'

export default class Notfound extends Component {

  render(){
    return(
      <Internal>
        <div className="container-fluid h-100">
            <div className="row align-items-center h-100">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
                    <h1>Oops! Página não encontrada!</h1>
                </div>
            </div>
        </div>
      </Internal>
    )
  }
}
