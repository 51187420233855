import React from 'react';

export default class Loading extends React.Component {

    render() {

        return (
            <div className="container-fluid h-100 overlay" style={{ display: this.props.visible ? 'block' : 'none' }}>
                <div className="row align-items-center h-100">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
                        <span className="progress">
                            <img src='/img/loader.gif' alt="" width="100" height="100"/>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
