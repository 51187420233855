import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import withRouter from 'react-router-dom/withRouter'

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleDropdownCadastros = this.toggleDropdownCadastros.bind(this);
    this.toggleDropdownArquivos = this.toggleDropdownArquivos.bind(this);
    this.toggleDropdownRelatorios = this.toggleDropdownRelatorios.bind(this);
    this.toggleDropdownAdmin = this.toggleDropdownAdmin.bind(this);
    this.toggleDropdownUser = this.toggleDropdownUser.bind(this);

    this.state = {
      isOpen: false,
      dropdownOpenCadastros: false,
      dropdownOpenArquivos: false,
      dropdownOpenRelatorios: false,
      dropdownOpenAdmin: false,
      dropdownOpenUser: false,
      user: null,
      year: new Date().getFullYear()
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  toggleDropdownCadastros(){
    this.setState({
      dropdownOpenCadastros: !this.state.dropdownOpenCadastros
    });
  }
  toggleDropdownArquivos(){
    this.setState({
      dropdownOpenArquivos: !this.state.dropdownOpenArquivos
    });
  }
  toggleDropdownRelatorios(){
    this.setState({
      dropdownOpenRelatorios: !this.state.dropdownOpenRelatorios
    });
  }
  toggleDropdownAdmin(){
    this.setState({
      dropdownOpenAdmin: !this.state.dropdownOpenAdmin
    });
  }
  toggleDropdownUser(){
    this.setState({
      dropdownOpenUser: !this.state.dropdownOpenUser
    });
  }

  componentDidMount(){

  }

  componentWillMount(){
    let userstorage = localStorage.getItem(btoa('user'));
    var user = null
    if (userstorage !== null){
        user = JSON.parse(atob(userstorage));
        this.setState({
          user: user
        })
    }
  }

  menuAdmin(){
    if (this.state.user == null){
      return;
    }
    if (this.state.user.administrador===1){
      return (
        <Dropdown nav isOpen={this.state.dropdownOpenAdmin} toggle={this.toggleDropdownAdmin}>
          <DropdownToggle nav caret>
            Administração
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=>this.go('/usuarios')}>Gerenciamento de Usuários</DropdownItem>
            <DropdownItem>Logs de Erros</DropdownItem>
            <DropdownItem onClick={()=>this.go('/configuracoes') }>Configurações Gerais</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )
    }
  }

  logout(){
    localStorage.clear();
    this.props.history.push("/login");
  }

  go(route){
    this.props.history.push(route);
  }


  render() {
    return (
      <div>
        <Navbar color="primary" dark expand="md" className="fixed-top">
          <NavbarBrand href="/">Taxview</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <Dropdown nav isOpen={this.state.dropdownOpenCadastros} toggle={this.toggleDropdownCadastros}>
                <DropdownToggle nav caret>
                  Cadastros
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={()=>this.go('/empresas') }>Empresas</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown nav isOpen={this.state.dropdownOpenArquivos} toggle={this.toggleDropdownArquivos}>
                <DropdownToggle nav caret>
                  Arquivos Digitais
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={()=>this.go('/arquivos') }>Carregar / Visualizar</DropdownItem>
                  <DropdownItem style={{ display: 'none'}}>Log de Carregamento</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown nav isOpen={this.state.dropdownOpenRelatorios} toggle={this.toggleDropdownRelatorios}>
                <DropdownToggle nav caret>
                  Relatórios
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={()=>this.go('/relatorios/analiticos') }>Análise</DropdownItem>
                  <DropdownItem onClick={()=>this.go('/relatorios/cruzamento') }>Cruzamento</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              { this.menuAdmin() }
            </Nav>
            <Nav navbar className="ml-auto">
              <Dropdown nav isOpen={this.state.dropdownOpenUser} toggle={this.toggleDropdownUser}>
                <DropdownToggle nav caret>
                  Olá, { this.state.user!==null ? this.state.user.nome : '' }
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={()=>this.go('/alterarsenha') }>Alterar Senha</DropdownItem>
                  <DropdownItem onClick={()=>this.logout()}>Sair</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Navbar>
        <nav className="navbar fixed-bottom navbar-dark bg-primary">
          <a className="navbar-brand footer" href="#">&copy; 2012-{ this.state.year } Taxview - Inteligência Fiscal Digital</a>
        </nav>

      </div>
    );
  }
}

export default withRouter(Navigation)
