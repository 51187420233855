import axios from 'axios';
import config from '../config';

export default class EmpresaService {

    token = undefined;

    constructor(token){
        this.token = token;
    }

    base(){
        return axios.create({
            baseURL: config.API_LOCATION,
            headers: {
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.token,
                'Content-Language': 'pt_BR',
                'Accept': 'application/json'
            }
          });
    }

    all(id){
	    return this.base().get('empresas?id='+id);
    }

    navigate(id,page,searchValue){
      return this.base().get('empresas/navigate?id='+id+'&page='+page+'&searchValue='+searchValue)
    }

    save(id,cnpj,razaoSocial,idUsuario){
      var body = {
        id: id,
        cnpj: cnpj,
        razaoSocial: razaoSocial,
        idUsuario: idUsuario
      }

      return this.base().post('empresas/save',body)
    }

    get(id){
      return this.base().get('empresa/'+id)
    }

    remove(id){
      return this.base().delete('/empresa/'+id)
    }

}
