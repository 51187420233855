import React from 'react'
import Generic from '../Generic'
import withRouter from 'react-router-dom/withRouter'
import { Table } from 'reactstrap'
import TokenService from '../../Services/TokenService';
import NfeService from '../../Services/NfeService';
import Loading from '../Tools/Loading'
import ModalAlert from '../Tools/ModalAlert'


class Nfes extends Generic {
  constructor(props){
    super(props)
    this.state = {
      loading: false,
      modal: {
        showModal: false,
        title: '',
        message: ''
      },
    }
  }

  openNfes(month,year){
    this.props.history.push({
      pathname: "/nfe_detalhes",
      search: '?year='+year+"&month="+month
    })
  }

  exportExcel(month,year){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new NfeService(token).exportExcel(localStorage.getItem('empresa'),year,month)
      .then(res=>{
        let filename = res.data.return_value
        var message = "<div class='text-center'>Para baixar o arquivo, clique no ícone abaixo:</div><br/>"
        message = message + "<div class='text-center'><a href='"+filename+"'><i class='fas fa-file-excel fa-3x'></i></a></div>"
        this.setState({
          loading:false,
          modal: {
              showModal: true,
              title: 'Arquivo gerado com sucesso',
              message: message
          }
        });
      })
      .catch(error => {
        console.log(error)
        this.setState({loading:false})
      })
    })
    .catch(error=>{
      console.log(error)
      this.setState({loading:false})
    })
  }

  render(){
      return(
        <div>
          <Loading visible={this.state.loading}/>
          <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
          <Table responsive>
            <thead>
              <tr>
                <th style={{width: '55%'}}>Empresa</th>
                <th>Período</th>
                <th style={{width: '15%'}}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.files.map(file => {
                    return (
                      <tr key={file.id}>
                        <td>{ file.MATRIZFILIAL==='0001' ? "Matriz-"+file.MATRIZFILIAL : "Filial-"+file.MATRIZFILIAL }</td>
                        <td>{ file.MES}/{file.ANO}</td>
                        <td>
                          <a href="javascript:void(0)" alt="" onClick={()=>this.openNfes(file.MES,file.ANO)}><i className="fas fa-folder-open"></i></a>&nbsp;
                          <a href="javascript:void(0)" alt="" onClick={()=>this.exportExcel(file.MES,file.ANO)}><i className="fas fa-file-excel"></i></a>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </Table>
      </div>
    )
  }
}

export default withRouter(Nfes)