import React from 'react'
import Internal from '../Layouts/Internal'
import Generic from '../Generic'
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  FormGroup,
  Label,
  Input
 } from 'reactstrap';
import TokenService from '../../Services/TokenService'
import ModalAlert from '../Tools/ModalAlert'
import SettingsService from '../../Services/SettingsService';

export default class Settings extends Generic {

  constructor(props){
    super(props)
    this.state = {
      loading: false,
      modal: {
        showModal: false,
        title: '',
        message: ''
      },
      templates: '',
      filesFolder: '',
      company: '',
      masterpassword: ''
    }
  }

  componentWillMount(){
    this.loadSettings()
  }

  loadSettings(){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new SettingsService(token).get()
      .then(res=>{
        this.setState({
          templates: res.data.return_value.LocalArquivosTemplate,
          filesFolder: res.data.return_value.LocalArquivoNfe,
          company: res.data.return_value.empresa,
          masterpassword: res.data.return_value.senhaMaster,
          loading: false
        })
      })
      .catch(error=>{
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      this.showError(error.response.status, error.response)
    })
  }

  save(){
    var body = {
      templates: this.state.templates,
      filesFolder: this.state.filesFolder,
      company: this.state.company,
      masterpassword: this.state.masterpassword
    }
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res => {
      let token = res.data.access_token
      new SettingsService(token).save(body)
      .then(res=> {
        this.setState(
          {
            loading: false,
            modal: {
              showModal: true,
              title: 'Configurações',
              message: 'Configurações salvas com sucesso!'
            },
          }
        )
      })
      .catch(error => {
        this.setState({loading:false})
        this.showError(error)
        this.loadSettings()
      })
    })
    .catch(error => {
      this.setState({loading:false})
      this.showError(error)
    })
  }

  render(){
    return(
      <Internal title="Configurações" loading={this.state.loading} showActivateEmpresa={false}>
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 mx-auto">
          <Card>
            <CardHeader className="login">Configurações</CardHeader>
            <CardBody>
              <FormGroup>
                <Label for="templates">Localização Templates de Relatórios</Label>
                <Input type="text" name="templates" id="templates" value={this.state.templates} onChange={this.handleChange} placeholder="Forneça a localização no servidor dos templates de relatórios" />
              </FormGroup>
              <FormGroup>
                <Label for="filesFolder">Localização para gravação de arquivos (EFDs, Nfes, etc.)</Label>
                <Input type="text" name="filesFolder" id="filesFolder" value={this.state.filesFolder} onChange={this.handleChange} placeholder="Forneça a localização onde os arquivos serão salvos no servidor" />
              </FormGroup>
              <FormGroup>
                <Label for="company">Nome da empresa</Label>
                <Input type="text" name="company" id="company" value={this.state.company} onChange={this.handleChange} placeholder="Forneça o nome da empresa" />
              </FormGroup>
              <FormGroup>
                <Label for="masterpassword">Senha Master</Label>
                <Input type="text" name="masterpassword" id="masterpassword" value={this.state.masterpassword} onChange={this.handleChange} placeholder="Forneça a senha master" />
              </FormGroup>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={()=>this.save()}><i className="fas fa-sync-alt"></i> Atualizar</Button>
            </CardFooter>
          </Card>
        </div>
        <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
      </Internal>
    )
  }

}