import React from 'react'
import External from '../Layouts/External'
import Generic from '../Generic'
import ModalAlert from '../Tools/ModalAlert'
import { Card, Button, CardHeader, CardFooter, CardBody,
  CardTitle, FormGroup, Label, Input } from 'reactstrap';

import TokenService from '../../Services/TokenService'
import UserService from '../../Services/UserService'

export default class ForgotPassword extends Generic {
    constructor(props){
        super(props)
        this.state = {
            email: '',
            modal: {
                showModal: false,
                title: '',
                message: ''
            },
            loading: false,
            year: new Date().getFullYear()
          }
    }

    forgotPassword(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res => {
            let token = res.data.access_token
            new UserService(token).forgotpassword(this.state.email)
            .then(res => {
                this.setState(
                    {
                        loading: false,
                        email: '',
                        modal: {
                            showModal: true,
                            title: 'Esqueci Senha',
                            message: res.data.return_value
                        }
                    }
                )
            })
            .catch(error => {
                this.setState({loading:false,email:''})
                this.showError(error)
            })
        })
        .catch(error => {
            this.setState({loading:false})
            this.showError(error)
        })
    }

    render(){
        return(
            <External loading={this.state.loading}>
                <Card className="login">
                <CardHeader className="text-center login" tag="h2">Esqueci a Senha</CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label for="email">E-mail:</Label>
                        <Input type="text" name="email" id="email" value={this.state.email} onChange={this.handleChange} placeholder="Digite o seu email e enviaremos uma mensagem para troca de senha" onKeyPress={this.onKeyPress}/>
                    </FormGroup>
                    <Button color="primary" block onClick={() => this.forgotPassword()}><i className="fas fa-location-arrow"></i> Solicitar</Button>{' '}
                    <Button color="danger" block onClick={this.props.history.goBack}><i className="fas fa-backspace"></i> Voltar</Button>{' '}
                </CardBody>
                <CardFooter className="text-right login">
                    &copy; 2012-{ this.state.year } Taxview - Inteligência Fiscal Digital
                </CardFooter>
                </Card>
                <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
            </External>
        )
    }
}