import React from 'react'
import Internal from '../Layouts/Internal'
import Generic from '../Generic';
import { Table, Alert, Button } from 'reactstrap'
import TokenService from '../../Services/TokenService'
import ModalAlert from '../Tools/ModalAlert'
import NfeService from '../../Services/NfeService'
import queryString from 'query-string'
import Paginator from '../Tools/Paginator'

export default class NfeDetails extends Generic {

  constructor(props){
      super(props)
      this.state = {
        loading:false,
        modal: {
            showModal: false,
            title: '',
            message: ''
          },
        nfes: [],
        current_page:1,
        last_page:1,
        month: 0,
        year: 0
      }
  }

  componentDidMount(){
    const values = queryString.parse(this.props.location.search);
    this.setState({
      month: values.month,
      year: values.year
    })
    this.loadNfes()
  }

  loadNfes(){
    new TokenService().getToken()
    .then(res => {
      let token = res.data.access_token
      new NfeService(token).getNfes(localStorage.getItem('empresa'),this.state.year,this.state.month,this.state.current_page)
      .then(res=>{
        console.log(res)
        this.setState({
          loading:false,
          current_page: res.data.return_value.current_page,
          last_page: res.data.return_value.last_page,
          nfes: res.data.return_value.data
        })
        window.scrollTo(0, 0)
      })
      .catch(error => {
        this.setState({
          loading:false
        })
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({
        loading:false
      })
      this.showError(error)
    })
  }

  firstPage = () => {
    this.setState({
      current_page: 1
    })
    this.loadNfes()
  }

  previousPage = () => {
    if (this.state.current_page>1){
      this.setState({
        current_page: this.state.current_page-1
      })
      this.loadNfes()
    }
  }

  nextPage = () => {
    if (this.state.current_page<this.state.last_page){
      this.setState({
        current_page: this.state.current_page+1
      })
      this.loadNfes()
    }
  }

  lastPage = () => {
    this.setState({
        current_page: this.state.last_page
    })
    this.loadNfes()
  }

  drawTable(){
    if (this.state.nfes.length === 0){
      return (
          <Alert color="info">
              <div className="text-center">Nenhuma NFe foi encontrada!</div>
          </Alert>
      )
    } else {
      return(
        <div>
          <br/>
          <Paginator last_page={this.state.last_page} current_page={this.state.current_page} onFirstPage={this.firstPage} onNextPage={this.nextPage} onPreviousPage={this.previousPage} onLastPage={this.lastPage}/>
          <Table responsive>
            <thead>
            <tr>
                <th style={{ textAlign:"center"}}>
                    Núm. Documento
                </th>
                <th style={{ textAlign:"center"}}>
                  Tipo de Operação
                </th>
                <th style={{ textAlign:"center"}}>
                  Data de Emissão
                </th>
                <th style={{ textAlign:"center"}}>
                  Valor
                </th>
                <th style={{ textAlign:"center"}}>
                  Versão
                </th>
                <th style={{ textAlign:"center"}}>
                  CNPJ Dest.
                </th>
                <th style={{ textAlign:"center"}}>
                  CNPJ Emit.
                </th>
            </tr>
            </thead>
            <tbody>
                {
                    this.state.nfes.map(nfe=>{
                        return(
                            <tr key={nfe.nNfe}>
                                <td style={{ textAlign:"center"}}>{ nfe.nNfe }</td>
                                <td style={{ textAlign:"center"}}>{ nfe.tipoOperacao==1 ? 'Saída' : 'Entrada' }</td>
                                <td style={{ textAlign:"center"}}>{ this.formatDate(nfe.dataEmissao) }</td>
                                <td style={{ textAlign:"right"}}>{ this.formatNumber(nfe.valor) }</td>
                                <td style={{ textAlign:"center"}}>{ nfe.versao }</td>
                                <td style={{ textAlign:"center"}}>{ this.formatCnpj(nfe.cnpjDest) }</td>
                                <td style={{ textAlign:"center"}}>{ this.formatCnpj(nfe.cnpjEmit) }</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
        </div>
      )
    }
  }

  render(){
    return(
      <Internal title="Notas Fiscais Eletrônicas" loading={this.state.loading} showActivateEmpresa={true}>
        <Button color="primary" onClick={this.props.history.goBack}><i className="fas fa-backspace"></i> Voltar</Button>
        { this.drawTable() }
        <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
      </Internal>
    )
  }
}