import React from 'react'
import Generic from '../../Generic'
import Internal from '../../Layouts/Internal'
import ModalAlert from '../../Tools/ModalAlert'
import TokenService from '../../../Services/TokenService';
import AnalyticsReportsTypeService from '../../../Services/AnalyticsReportsTypeService';
import AnalyticsReportsService from '../../../Services/AnalyticReportsService'

import { FormGroup, Input, Label, Row, Col, Table, Button } from 'reactstrap'

export default class Analyze extends Generic {
    constructor(props){
        super(props)
        this.state = {
            loading:false,
            reportsTypes: [],
            reports: [],
            periods: [],
            companies: [],
            company: "",
            period: 0,
            files:[],
            file:0,
            type: 0,
            report_id: 0,
            showFilters: false,
            modal: {
                showModal: false,
                title: '',
                message: ''
            },
        }
    }

    loadReportTypes(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res => {
            let token = res.data.access_token
            new AnalyticsReportsTypeService(token).list()
            .then(res => {
                this.setState({
                    reportsTypes: res.data.return_value,
                    periods: [],
                    companies: [],
                    files:[]
                })
                this.setState({loading:false})
            })
            .catch(error=>{
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error => {
            this.setState({loading:false})
            this.showError(error)
        })
    }

    getByTypes(e){
        this.setState({
            type: e.target.value
        })
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res => {
            let token = res.data.access_token
            new AnalyticsReportsService(token).getByType(this.state.type)
            .then(res => {
                this.setState({
                    showFilters:false,
                    reports: res.data.return_value,
                    periods: [],
                    companies: [],
                    files:[]
                })
                this.setState({loading:false})
            })
            .catch(error=>{
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error => {
            this.setState({loading:false})
            this.showError(error)
        })
    }

    showReportData(report_id){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            new AnalyticsReportsService(token).getPeriods(this.state.type, this.globalUser.idUsuario,localStorage.getItem('empresa'))
            .then(res=>{
                this.setState({loading:false})
                this.setState({
                    report_id: report_id,
                    showFilters: true,
                    periods: res.data.return_value,
                    companies: [],
                    files:[],
                    period: 0,
                    company: "",
                    file: 0,
                })
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({loading:false})
            this.showError(error)
        })
    }

    getCompanies(e){
        this.setState({
            period: e.target.value
        })
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            new AnalyticsReportsService(token).getCompanies(this.state.type, this.globalUser.idUsuario,localStorage.getItem('empresa'),this.state.period)
            .then(res=>{
                this.setState({loading:false})
                console.log(res);
                this.setState({
                    companies: res.data.return_value,
                    files:[],
                    company: "",
                    file: 0
                })
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({loading:false})
            this.showError(error)
        })
    }

    getFiles(e){
        this.setState({
            company: e.target.value
        })
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            new AnalyticsReportsService(token).getFiles(this.state.type, this.globalUser.idUsuario,localStorage.getItem('empresa'),this.state.period,this.state.company)
            .then(res=>{
                this.setState({loading:false})
                console.log(res);
                this.setState({
                    files: res.data.return_value,
                    file: 0
                })
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({loading:false})
            this.showError(error)
        })
    }

    chooseFile(e) {
        this.setState({
            file: e.target.value
        })
    }

    generateReport(){
        this.setState({loading:true})
        new TokenService().getToken()
        .then(res=>{
            let token = res.data.access_token
            new AnalyticsReportsService(token).generateReport(this.state.type, this.globalUser.idUsuario,localStorage.getItem('empresa'),this.state.period,this.state.company,this.state.report_id,this.state.file)
            .then(res=>{
                let filename = res.data.return_value
                var message = "<div class='text-center'>Para baixar o arquivo, clique no ícone abaixo:</div><br/>"
                message = message + "<div class='text-center'><a href='"+filename+"'><i class='fas fa-file-excel fa-3x'></i></a></div>"
                this.setState({
                    loading:false,
                    modal: {
                        showModal: true,
                        title: 'Arquivo gerado com sucesso',
                        message: message
                    }
                });
            })
            .catch(error => {
                this.setState({loading:false})
                this.showError(error)
            })
        })
        .catch(error=>{
            this.setState({loading:false})
            this.showError(error)
        })
    }

    drawTable(){
        if (this.state.reports.length>0){
            return(
                <div>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>
                                Nome
                            </th>
                            <th>
                                Descrição
                            </th>
                            <th>
                                &nbsp;
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.reports.map(report=>{
                                    return(
                                        <tr key={report.IdRelatorioAnalise}>
                                            <td>
                                                <i style={{ display: this.state.report_id===report.IdRelatorioAnalise ? 'inline' : 'none' }} className="fas fa-check-circle"></i>{ report.Descricao }
                                            </td>
                                            <td>{ report.Nome }</td>
                                            <td><a href="javascript:void(0)" alt="" onClick={()=>this.showReportData(report.IdRelatorioAnalise)}><i className="fas fa-folder-open"></i></a></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            )
        }
    }

    componentWillMount(){
        this.loadReportTypes()
    }

    selectReports(){
        return (
            <Row>
                <Col xs="12" sm="12" md="6" lg="6" className="mx-auto">
                    <FormGroup>
                        <Label for="reportTypes"><strong>Tipos de Arquivos</strong></Label>
                        <Input type="select" name="reportTypes" id="reportTypes" onChange={(event)=>this.getByTypes(event)}>
                            <option value="0">Selecione uma opção</option>
                            {
                                this.state.reportsTypes.map(type => {
                                    return (
                                        <option value={type.IdRelatorioAnaliseCategoria} key={type.IdRelatorioAnaliseCategoria}>{type.Categoria}</option>
                                    )
                                })
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        )
    }

    drawFilters(){
        if (this.state.showFilters){
            return (
                <div>
                    <Row>
                        <Col xs="12" sm="12" md="4" lg="4">
                            <FormGroup>
                                <Label for="period"><strong>Ano</strong></Label>
                                <Input type="select" name="period" id="period" value={this.state.period} onChange={(event)=>this.getCompanies(event)}>
                                    <option value="0">Selecione um período</option>
                                    {
                                        this.state.periods.map(period => {
                                            return (
                                                <option value={period.Periodo} key={period.Periodo}>{period.Periodo}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4">
                            <FormGroup>
                                <Label for="company"><strong>Matriz / Filial</strong></Label>
                                <Input type="select" name="company" id="company" onChange={(event)=>this.getFiles(event)}>
                                    <option value="">Selecione a Matriz ou Filial</option>
                                    {
                                        this.state.companies.map(company => {
                                            return (
                                                <option value={company} key={company}>{company!=="0001"?"FILIAL "+company : "MATRIZ"}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4">
                            <FormGroup>
                                <Label for="file"><strong>Período</strong></Label>
                                <Input type="select" name="file" id="file" onChange={(event)=>this.chooseFile(event)}>
                                    <option value="0">Selecione o mês ou gere todos os meses</option>
                                    {
                                        this.state.files.map(file => {
                                            return (
                                                <option value={file.id} key={file.id}>{this.numberMonth2Text(file.mes)} / {file.ano}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="primary" onClick={()=>this.generateReport()}>Gerar o relatório</Button>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    drawContent(){
        if(localStorage.getItem('empresa')!==null){
            return (
                <div>
                    { this.selectReports() }
                    { this.drawTable() }
                    { this.drawFilters() }
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    render(){
        return(
            <Internal title="Relatórios Analíticos" loading={this.state.loading} showActivateEmpresa={true}>
                { this.drawContent() }
                <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
            </Internal>
        )
    }
}