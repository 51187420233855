import axios from 'axios';
import config from '../config';

export default class CrossingReportsService {

    token = undefined;

    constructor(token){
        this.token = token;
    }

    base(){
        return axios.create({
            baseURL: config.API_LOCATION,
            headers: {
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.token,
                'Content-Language': 'pt_BR',
                'Accept': 'application/json'
            }
          });
    }

    getReports(){
	    return this.base().get('crossingreports/getReports');
    }

    returnInformationTable(cnpj,year,report,idUsuario,idEmpresa){
        var body = {
            cnpj: cnpj,
            year: year,
            report: report,
            idUsuario: idUsuario,
            idEmpresa: idEmpresa
        }

        return this.base().post('crossingreports/returnInformationTable',body);
    }

    generateReport(cnpj,year,report,idUsuario,idEmpresa){
        var body = {
            cnpj: cnpj,
            year: year,
            report: report,
            idUsuario: idUsuario,
            idEmpresa: idEmpresa
        }

        return this.base().post('crossingreports/generateReport',body);
    }

}
