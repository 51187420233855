import axios from 'axios';
import config from '../config';

export default class TokenService {

    base(){
        return axios.create({
            baseURL: config.OAUTH_TOKEN,
            headers: {
                'Content-Type':  'application/json'
            }
          });
    }

    getToken(){
        const body = {
            grant_type:"client_credentials",
            client_id: config.CLIENT_ID,
            client_secret: config.CLIENT_SECRET,
            scope: ''
        }

        return this.base().post('',body);
    }

}
