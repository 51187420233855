import React from 'react'
import Internal from '../../Layouts/Internal'
import TokenService from '../../../Services/TokenService'
import UserService from '../../../Services/UserService'
import Generic from '../../Generic';
import ModalAlert from '../../Tools/ModalAlert'

import { UncontrolledTooltip, Row, Col, Alert, CardColumns, Card, CardHeader, CardBody, CardFooter, ButtonGroup, Button } from 'reactstrap'
import Form from './Form';

export default class Users extends Generic {
  constructor(props){
    super(props)
    this.state = {
      loading:false,
      modal: {
        showModal: false,
        title: '',
        message: ''
      },
      users:[],
      id: 0,
      newUser: false
    }
  }

  componentWillMount(){
    this.listUsers()
  }

  listUsers(){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new UserService(token).list()
      .then(res=>{
        console.log(res)
        this.setState({
          loading:false,
          users: res.data.return_value
        })
      })
      .catch(error => {
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error=>{
      this.setState({loading:false})
      this.showError(error)
    })
  }

  adminButton(index){
    if (this.state.users[index].administrador === 1){
      return (
        <Button color="primary" active id={"btAdmin"+this.state.users[index].idUsuario} onClick={()=>this.makeAdmin(index)}>
          <i className="fas fa-users"></i>
        </Button>
      ) 
    } else {
      return (
      <Button color="primary" id={"btAdmin"+this.state.users[index].idUsuario} onClick={()=>this.makeAdmin(index)}>
        <i className="fas fa-users"></i>
      </Button>
      ) 
    }
  }

  firstAccessButton(index){
    if (this.state.users[index].primeiroAcesso === 1){
      return (
        <Button color="primary" active id={"btFirstAccess"+this.state.users[index].idUsuario} onClick={()=>this.makeFirstAccess(index)}>
          <i className="fas fa-unlock-alt"></i>
        </Button>
      ) 
    } else {
      return (
      <Button color="primary" id={"btFirstAccess"+this.state.users[index].idUsuario} onClick={()=>this.makeFirstAccess(index)}>
        <i className="fas fa-unlock-alt"></i>
      </Button>
      ) 
    }
  }

  makeAdmin(index){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res => {
      let token = res.data.access_token
      new UserService(token).toggleAdmin(this.state.users[index].idUsuario)
      .then(res => {
        this.setState({loading:false})
        var users = this.state.users;
        users[index].administrador = users[index].administrador == 1 ? 0 : 1;
        this.setState({
          users: users
        })
      })
      .catch(error => {
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error => {
      this.setState({loading:false})
      this.showError(error)
    })
  }

  makeFirstAccess(index){
    this.setState({loading:true})
    new TokenService().getToken()
    .then(res => {
      let token = res.data.access_token
      new UserService(token).toggleFirstAccess(this.state.users[index].idUsuario)
      .then(res => {
        this.setState({loading:false})
        var users = this.state.users;
        users[index].primeiroAcesso = users[index].primeiroAcesso == 1 ? 0 : 1;
        this.setState({
          users: users
        })
      })
      .catch(error => {
        this.setState({loading:false})
        this.showError(error)
      })
    })
    .catch(error => {
      this.setState({loading:false})
      this.showError(error)
    })
  }

  drawTable(){
    if (this.state.users.length === 0){
      return (
        <Alert color="primary">
          <i className="fas fa-exclamation-triangle"></i> Nenhum usuário encontrado!
        </Alert>
      )
    } else {
      return (
          <CardColumns>
            {
              this.state.users.map((user, index)=> {
                return (
                  <Card key={user.idUsuario}>
                    <CardHeader className="internal">
                      Login: { user.login }
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs="4" sm="4" md="4" lg="4">
                          <strong>Nome</strong>
                        </Col>
                        <Col xs="8" sm="8" md="8" lg="8">
                          {user.nome}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="4" sm="4" md="4" lg="4">
                          <strong>E-mail</strong>
                        </Col>
                        <Col xs="8" sm="8" md="8" lg="8">
                          {user.email}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="4" sm="4" md="4" lg="4">
                          <strong>Qtde Empresas Permitidas:</strong>
                        </Col>
                        <Col xs="8" sm="8" md="8" lg="8">
                          {user.qtdeEmpresasPermitidas}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="4" sm="4" md="4" lg="4">
                          <strong>Data de Expiração:</strong>
                        </Col>
                        <Col xs="8" sm="8" md="8" lg="8">
                          {user.dataExpiracao===null ? 'Não expira' : this.formatDateTime(user.dataExpiracao)}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <ButtonGroup>
                        <Button color="primary" onClick={()=>this.edit(user.idUsuario)}>
                          <i className="fas fa-pencil-alt"></i>
                        </Button>
                        { this.adminButton(index)}
                        { this.firstAccessButton(index)}
                      </ButtonGroup>
                      <UncontrolledTooltip placement="right" target={"btAdmin"+user.idUsuario}>
                        É administrador?
                      </UncontrolledTooltip>
                      <UncontrolledTooltip placement="right" target={"btFirstAccess"+user.idUsuario}>
                        Altera senha no primeiro acesso?
                      </UncontrolledTooltip>
                    </CardFooter>
                  </Card>
                )
              })
            }
          </CardColumns>
      )
    }
  }

  newUser() {
    this.setState({ newUser: true, id: 0})
  }

  edit(id){
    this.setState({
      newUser: true,
      id: id,
    })
  }

  handleOnCancelForm(){
    this.setState({ newUser: false})
  }

  handleOnSaveForm(){
    this.setState(
      {
        newUser: false,
        modal: {
          showModal: true,
          title: 'Cadastro / Edição de Usuários',
          message: 'Operação realizada com sucesso!'
        }
      }
    )
    this.listUsers()
  }

  switchForm(){
    if (this.state.newUser){
      return (
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 mx-auto">
            <Form id={this.state.id} onCancel={()=>this.handleOnCancelForm()} onSave={()=>this.handleOnSaveForm()}></Form>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <Button color="danger" className="btn-circle btn-lg fixed" onClick={()=>this.newUser()}><i className="fas fa-user-plus"></i></Button>
          {this.drawTable()}
        </div>
      )
    }
  }

  render(){
    return(
      <Internal title="Gestão de Usuários" loading={this.state.loading}>
        { this.switchForm() }
        <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
      </Internal>
    )
  }
}