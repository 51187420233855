import axios from 'axios';
import config from '../config';

export default class NfeService {

    token = undefined;

    constructor(token){
        this.token = token;
    }

    base(){
        return axios.create({
            baseURL: config.API_LOCATION,
            headers: {
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.token,
                'Content-Language': 'pt_BR',
                'Accept': 'application/json'
            }
          });
    }

    getNfeProcessar(idEmpresa,idStatusProcessamento){

        if(idStatusProcessamento===''){
            idStatusProcessamento = "-1";
        }
        return this.base().get('/nfes/processar?idEmpresa='+idEmpresa+'&idStatusProcessamento='+idStatusProcessamento);
    }

    getNfeProcessadas(idNfeProcessar,idStatusProcessamento,page){
        if(idStatusProcessamento===''){
            idStatusProcessamento = "-1"
        }

        return this.base().get('/nfes/processadas?idNfeProcessar='+idNfeProcessar+'&idStatusProcessamento='+idStatusProcessamento+"&page="+page);
    }

    getNfes(idEmpresa,year,month,page){
        return this.base().get('/nfes?idEmpresa='+idEmpresa+"&year="+year+"&month="+month+"&page="+page)
    }

    exportExcel(idEmpresa,year,month){
        return this.base().get('/nfes/exportExcel?idEmpresa='+idEmpresa+"&year="+year+"&month="+month)
    }

}
