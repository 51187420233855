import axios from 'axios';
import config from '../config';

export default class AnalyticsReportsService {

    token = undefined;

    constructor(token){
        this.token = token;
    }

    base(){
        return axios.create({
            baseURL: config.API_LOCATION,
            headers: {
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.token,
                'Content-Language': 'pt_BR',
                'Accept': 'application/json'
            }
          });
    }

    getByType(type){
	    return this.base().get('analyticreports/getByType/'+type);
    }

    getPeriods(type,idUsuario,idEmpresa){
        return this.base().get('analyticreports/getPeriods?type='+type+"&idUsuario="+idUsuario+"&idEmpresa="+idEmpresa);
    }

    getCompanies(type,idUsuario,idEmpresa,year){
        return this.base().get('analyticreports/getCompanies?type='+type+"&idUsuario="+idUsuario+"&idEmpresa="+idEmpresa+"&year="+year);
    }

    getFiles(type,idUsuario,idEmpresa,year,company){
        return this.base().get('analyticreports/getFiles?type='+type+"&idUsuario="+idUsuario+"&idEmpresa="+idEmpresa+"&year="+year+"&company="+company);
    }

    generateReport(type,idUsuario,idEmpresa,year,company,report_id,file){
        return this.base().get('analyticreports/generateReport?type='+type+"&idUsuario="+idUsuario+"&idEmpresa="+idEmpresa+"&year="+year+"&company="+company+"&report_id="+report_id+"&file="+file);
    }

}
