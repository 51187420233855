import { Component } from 'react'

export default class Generic extends Component {

    globalUser = null;

    constructor(){
        super();
        this.handleChange = this.handleChange.bind(this);
        let userstorage = localStorage.getItem(btoa('user'));
        if (userstorage !== null){
            this.globalUser = JSON.parse(atob(userstorage));
        }
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleModalOk = () => {
        this.setState({
            modal: {
                showModal: false,
                title: '',
                message: ''
            }
        });
        if (this.state.redirect){
          this.props.history.push(this.state.url);
        }
    }

    formatCnpj = (value) => {
      // 01234567890123
      // 01752373000181
      if(value===null){
        return ''
      }
      if (value!=='' || value!==undefined ){
        try {
          return value.substr(0,2)+"."+value.substr(2,3)+"."+value.substr(5,3)+"/"+value.substr(8,4)+"-"+value.substr(12,2)
        } catch (error) {
          return value
        }
        
      }
    }

    formatDate = (value) => {
      if (value!=='' && value !== undefined){
        let dateArray = value.split('-')

        if (dateArray[2]===undefined){
          return value;
        }

        return dateArray[2]+"/"+dateArray[1]+"/"+dateArray[0]
      }
    }

    formatDateTime = (value) => {
      if (value!=='' || value !== undefined){
        let dateArray1 = value.split(' ')
        let dateArray = dateArray1[0].split('-')

        if (dateArray[2]===undefined){
          return value;
        }

        return dateArray[2]+"/"+dateArray[1]+"/"+dateArray[0]
      }
    }

    formatNumber = (value) => {
      var number = parseFloat(value).toFixed(2).split('.');
      number[0] = number[0].split(/(?=(?:...)*$)/).join('.');
      return number.join(',');
    }

    numberMonth2Text = (value) => {
      var month = ""
      switch (value) {
        case 1:
          month = "JAN"
          break;
        case 2:
          month = "FEV"
          break;
        case 3:
          month = "MAR"
          break;
        case 4:
          month = "ABR"
          break;
        case 5:
          month = "MAI"
          break;
        case 6:
          month = "JUN"
          break;
        case 7:
          month = "JUL"
          break;
        case 8:
          month = "AGO"
          break;
        case 9:
          month = "SET"
          break;
        case 10:
          month = "OUT"
          break;
        case 11:
          month = "NOV"
          break;
        case 12:
          month = "DEZ"
          break;
        default:
          break;
      }
      return month
    }

    showError = (error) => {
      var data = ""
      var status = ""
      var response = ""

      if (error.response == undefined){
        status = 500
      } else {
        status = error.response.status
        response = error.response
      }
      
      switch (status) {
        case 401:
          this.setState({
            modal: {
              showModal: true,
              title: "Erro de API",
              message: "Erro  ao autenticar a api no servidor!"
            }
          })
          break;
        case 500:
            this.setState({
              modal: {
                showModal: true,
                title: "Erro Inesperado",
                message: "Houve um erro inesperado no sistema! Já fomos informados da falha e já iremos verificar! Volte em alguns minutos."
              }
            })
          break;
        case 422:
          data = response.data
          var message = ''
          for (var i = 0; i < data.return_value.length; i++) {
            message = message + data.return_value[i] + "<br/>"
          }
          this.setState({
            modal: {
              showModal: true,
              title: data.message,
              message: message
            }
          })
          break;
        case 400:
          data = response.data
          this.setState({
            modal: {
              showModal: true,
              title: data.message,
              message: data.return_value
            }
          })
          break;
        default:
          this.setState({
            modal: {
              showModal: true,
              title: "Erro Inesperado",
              message: "Houve um erro inesperado no sistema! Já fomos informados da falha e já iremos verificar! Volte em alguns minutos."
            }
          })
          break;
      }
    }
}
