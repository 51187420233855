import React from 'react'
import Internal from '../Layouts/Internal'
import Generic from '../Generic';
import { Button, Table, Alert, Card, CardHeader, CardBody,CardText,CardTitle } from 'reactstrap'
import TokenService from '../../Services/TokenService';
import ModalAlert from '../Tools/ModalAlert'
import NfeService from '../../Services/NfeService';

export default class NfeProcessar extends Generic {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            modal: {
                showModal: false,
                title: '',
                message: ''
              },
            nfes: []
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        this.intervalId = setInterval(this.loadProcessingNfes.bind(this), 5000);
    }

    componentWillMount(){
        this.loadProcessingNfes();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
      }

    loadProcessingNfes(){
        this.setState({
            loading:true
        })
        new TokenService().getToken()
        .then(res => {
            let token = res.data.access_token
            new NfeService(token).getNfeProcessar(localStorage.getItem('empresa'),1)
            .then(res => {
                console.log(res.data)
                this.setState({
                    nfes: res.data.return_value,
                    loading:false
                })
            })
            .catch (error => {
                this.setState({
                    loading:false
                })
                this.showError(error)
            })
        })
        .catch(error => {
            this.setState({
                loading:false
            })
            this.showError(error)
        })
    }

    drawTable(){
        if (this.state.nfes.length === 0){
            return (
                <Alert color="info">
                    <div className="text-center">Nenhum lote sendo processado!</div>
                </Alert>
            )
        } else {
            return(
                <div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>
                            Arquivo de Lote
                        </th>
                        <th>
                            Qtde Xmls no Arquivo
                        </th>
                        <th>
                            Qtde Xmls Processados
                        </th>
                        <th>
                            Status Processamento
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.nfes.map(nfe=>{
                                var statusProcessamento = ""
                                switch (nfe.idStatusProcessamento) {
                                    case 0:
                                        statusProcessamento = "Para processar"
                                        break;
                                    case 1:
                                        statusProcessamento = "Em processamento"
                                        break;
                                    case 2:
                                        statusProcessamento = "Processado"
                                        break;
                                    case 3:
                                        statusProcessamento = "Processado parceialmente"
                                        break;
                                    default:
                                        break;
                                }
                                return(
                                    <tr>
                                        <td>{ nfe.arquivo }</td>
                                        <td>{ nfe.qtdeXml }</td>
                                        <td>{ nfe.qtdeXmlProcessado }</td>
                                        <td>{ statusProcessamento }</td>                                
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            )
        }
    }

    drawCard(){
        if (this.state.nfes.length === 0){
            return (
                <div className="col-xs-12 col-xm-12 col-md-6 col-lg-6 mx-auto">
                    <Alert color="info">
                        <div className="text-center">Nenhum lote sendo processado!</div>
                    </Alert>
                </div>
            )
        } else {
            return(
                <div class='col-xs-12 col-xm-12 col-md-6 col-lg-6 mx-auto'>
                    <Card>
                        <CardHeader tag="h4" className="login">
                            Arquivo em Processamento
                        </CardHeader>
                        <CardBody>
                            <CardTitle>{ this.state.nfes[0].arquivo }</CardTitle>
                            <CardText><strong>Quantidade de Xmls no Arquivo:</strong>{ this.state.nfes[0].qtdeXml}</CardText>
                            <CardText><strong>Quantidade de Xmls Processados:</strong>{ this.state.nfes[0].qtdeXmlProcessado}</CardText>
                            <CardText>
                                <small className="text-muted">&nbsp;</small>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
                
            )
        }
    }

    viewNfeLotes(){
        this.props.history.push('/nfes_lotes')
    }

    render(){
        return(
            <Internal loading={this.state.loading} title="Monitor de Processamento de Lote de Nfes" showActivateEmpresa={true}>
                <div className="col-xs-12 col-xm-12 col-md-6 col-lg-6 mx-auto">
                    <Button color="primary" onClick={this.props.history.goBack}><i className="fas fa-backspace"></i> Voltar</Button>&nbsp;&nbsp;
                    <Button color="primary" onClick={()=>this.viewNfeLotes()}><i className="fas fa-list-ol"></i> Todos os lotes</Button>
                </div>
                { this.drawCard() }
                <ModalAlert onOk={this.handleModalOk} isOpen={this.state.modal.showModal} title={this.state.modal.title} message={this.state.modal.message} />
            </Internal>
        )
    }
}