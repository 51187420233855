import axios from 'axios';
import config from '../config';

export default class UserService {

    token = undefined;

    constructor(token){
        this.token = token;
    }

    base(){
        return axios.create({
            baseURL: config.API_LOCATION,
            headers: {
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+this.token,
                'Content-Language': 'pt_BR',
                'Accept': 'application/json'
            }
          });
    }

    authenticate(login, password){
        let body = {
            login: login,
            senha: password
        }
	    return this.base().post('login',body);
    }

    forgotpassword(email){
        let body = {
            email: email
        }
        return this.base().post('usuario/esquecisenha',body)
    }

    changepassword(body){
        return this.base().post('usuario/redefinirSenha',body)
    }

    enableRedefinePassword(pass_token){
        return this.base().get('usuario/getRedefinirSenha?token='+pass_token)
    }

    list(){
        return this.base().get('usuario/all')
    }

    toggleAdmin(id){
        return this.base().get('usuario/toggleAdmin/'+id);
    }

    toggleFirstAccess(id){
        return this.base().get('usuario/toggleFirstAccess/'+id);
    }

    get(id){
        return this.base().get('usuario/get/'+id)
    }

    save(body){
        return this.base().post('usuario/save',body)
    }
}
