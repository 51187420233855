import React from 'react'
import Generic from '../Generic'
import TokenService from '../../Services/TokenService'
import ObrigacaoService from '../../Services/ObrigacaoService'
import { Table,Alert,UncontrolledTooltip} from 'reactstrap'
import FileUploadProgress  from '../FileUploadProgress/FileUploadProgress'
import config from '../../config';

export default class Obrigacao extends Generic {

  constructor(props){
    super(props)
    this.state={
      periods:[],
      processed: false,
      showUpload: false,
      files: [],
      urlUpload: config.API_LOCATION+'obrigacoes/uploadObrigacao',
    }
  }

  componentDidMount(){
    this.loadFile()
  }

  loadFile(){
    new TokenService().getToken()
    .then(res=>{
      let token = res.data.access_token
      new ObrigacaoService(token).getPeriodos(this.props.id,this.globalUser.idUsuario,localStorage.getItem('empresa'))
      .then(res=>{
        this.setState({
          periods: res.data.return_value,
          processed: true,
        })
      })
      .catch(error=>{
        this.setState({
          periods: [],
          processed: true,
        })
      })
    })
    .catch(error=>{
      console.log('Erro')
    })
  }

  viewLoadedFiles(year){
    this.props.history.push({
      pathname: "/arquivos_detalhes",
      search: '?type='+this.props.id+'&year='+year
    })
  }

  viewNfeProcessar(){
    this.props.history.push('/nfes_processar')
  }

  viewNfeLotes(){
    this.props.history.push('/nfes_lotes')
  }

  drawTable(){
    return(
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th style={{width: '85%'}}>Períodos</th>
              <th style={{width: '15%'}}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.periods.map(period => {
                  return (
                    <tr key={period.Periodo}>
                      <td>{ period.Periodo==undefined ? period.ANO: period.Periodo }</td>
                      <td>
                        <a href="javascript:void(0)" alt="" onClick={()=>this.viewLoadedFiles(period.Periodo)}><i className="fas fa-folder-open"></i></a>
                      </td>
                    </tr>
                  )
              })
            }
          </tbody>
        </Table>
    </div>
    )
  }

  addFileComponent(){
    if(!this.state.showUpload){
      return(
        <a href="javascript:void(0);" alt="" onClick={()=>this.uploadObrigacao()}><i className="fas fa-folder-plus fa-2x"></i></a>
      )
    } else {
      return(
        <div></div>
      )
    }
  }

  uploadObrigacao(){
    this.setState({
      showUpload:true
    })
  }

  cancelUploadObrigacao(){
    this.setState({
      showUpload:false
    })
  }

  uploadedObrigacao(e,request){
    console.log(request)
    this.setState({
      showUpload:false
    })
    this.props.onLoaded(1,"Arquivo carregado com sucesso!");
    this.loadFile()
  }

  errorUploadObrigacao(e,request){
    console.log(request)
    if (request.response == undefined){
      this.setState({
        showUpload:false,
      })
      this.props.onLoaded(0,"Erro ao carregar o arquivo!")
    } else {
      let response = JSON.parse(request.response)
      this.setState({
        showUpload:false,
      })
      if (request.status==500){
        this.props.onLoaded(0,response.message);
      } else if (request.status==422){
        var message = ''
        for (var i = 0; i < response.return_value.length; i++) {
          message = message + response.return_value[i] + "<br/>"
        }
        this.props.onLoaded(0,message);
      }
    }
  }

  drawNfeButtons(){
    if(this.props.name === "Nfe"){
      return (
        <div className='row'>
          <div className="col text-right">
            <a href="javascript:void(0);" id="monitor" onClick={()=>this.viewNfeProcessar()}><i className="fas fa-cogs"></i></a>&nbsp;&nbsp;
            <a href="javascript:void(0);" id="files" onClick={()=>this.viewNfeLotes()}><i className="fas fa-list-ol"></i></a>
            <UncontrolledTooltip placement="left" target={'monitor'}>
              Monitorar Processamento
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="right" target={'files'}>
              Arquivos enviados
            </UncontrolledTooltip>
          </div>
        </div>
      )
    }
  }

  drawUpload(){
    if (this.state.showUpload){
      return(
        <div className="row">
          <div className="col text-left">
            <FileUploadProgress key='ex1' url={this.state.urlUpload}
              onProgress={(e, request, progress) => {console.log('progress', e, request, progress);}}
              onLoad={ (e, request) => {this.uploadedObrigacao(e,request)}}
              onError={ (e, request) => {this.errorUploadObrigacao(e,request)}}
              onAbort={ (e, request) => {this.cancelUploadObrigacao();}}
              idUsuario = {this.globalUser.idUsuario}
              idEmpresa = {localStorage.getItem('empresa')}
              idObrigacao = {this.props.id}
              />
          </div>
        </div>
      )
    } else {
      return (
        <div>
        </div>
      )
    }
  }

  render(){
    if(this.state.periods.length==0 && this.state.processed===false){
      return(
        <span>Carregando Dados...</span>
      )
    } else {
      if (this.state.periods.length==0){
        return (
          <div>
            { this.drawUpload() }
            { this.addFileComponent() }
            { this.drawNfeButtons() }
            <Alert color="info">
              <i className="fas fa-info-circle"></i> Nenhum arquivo carregado!
            </Alert>
          </div>
        )
      } else {
        return(
          <div>
            { this.drawUpload() }
            {this.addFileComponent()}
            { this.drawNfeButtons() }
            <div className="content_card_body_files">
              {this.drawTable()}
            </div>
          </div>
        )
      }
    }
  }


}
